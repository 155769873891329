import axiosInstance from "../models/axios.instance";
import { HttpMethods, URLS } from "../models/constants";
import { HttpConfig } from "../models/types";

const addFollowup = (data: any): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.POST,
    url: URLS.ADD_FOLLOWUP,
    body: data,
  };
};
const editFollowup = (id: number, data: any): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.POST,
    url: `${URLS.EDIT_FOLLOWUP}${id}`,
    body: data,
  };
};

const getFollowupClients = (id: number): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.FOLLOWUP_CLIENTS}/${id}`,
  };
};
const getFollowupProjects = (id: number, value: number): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.FOLLOWUP_PROJECTS}/${id}/${value}`,
  };
};
const getFollowupScopes = (
  id: number,
  clntId: number,
  value: number
): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.FOOLOWUP_SCOPE_DROPDOWN}/${id}/${clntId}/${value}`,
  };
};
const getFollowupObservations = (ID: any): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.FOLLOWUP_OBSERVATIONS_DROPDOWN}/${ID}`,
  };
};
const getFollowupIssues = (
  clntId: number,
  projId?: number,
  planId?: number
): HttpConfig => {
  const project = projId ? `&project_id=${projId}` : "";
  const plan = planId ? `&plan_id=${planId}` : "";
  const url = `${URLS.FOLLOWUP_ISSUE_STATUS + clntId}${project}${plan}`;
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: url,
  };
};
const getFollowupMasterData = (id: number, value: number): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.FOLLOWUP_OBSERVATIONS_MASTERDATA}/${id}/${value}`,
  };
};
const getFollowupObservationsList = (auditId: number): HttpConfig => {
  const plan = auditId ? `?plan_id=${auditId}` : "";

  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.FOLLOWUP_OBSERVATIONS_LIST}${plan}`,
  };
};

const getFollowupComments = (ObservationID: number): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.FOLLOWUP_COMMENTS_LIST}/${ObservationID}`
  }
}

const getFollowupDocs = (ID: number): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.FOLLOWUP_FILE_DOWNLOAD}/${ID}`,
    headers: {
      responseType: "blob"
    }
  }
}


const followupServices = {
  getFollowupClients,
  getFollowupIssues,
  getFollowupMasterData,
  getFollowupObservations,
  getFollowupObservationsList,
  getFollowupProjects,
  getFollowupScopes,
  getFollowupComments,
  addFollowup,
  editFollowup,
  getFollowupDocs
};

export default followupServices;
