import axios from "axios";

const headers = {
  "X-Frame-Options": "sameorigin",
  "x-content-type-options": "nosniff",
  "X-XSS-Protection": "1; mode=block",
  "Content-Security-Policy":
    "default-src 'self';connect-src 'self';script-src 'self' https://cdn.jsdelivr.net 'unsafe-inline';style-src 'self' https://cdn.jsdelivr.net 'unsafe-inline';font-src;object-src 'self' application/pdf application/msword application/vnd.ms-excel application/vnd.ms-powerpoint;img-src 'self' https://fastapi.tiangolo.com https://www.w3.org data:;",
  "Strict-Transport-Security": "max-age=63072000; includeSubDomains; preload",
  "Referrer-Policy": "origin-when-cross-origin",
  "Cache-Control": "no-store",
  "X-Powered-By": "",
  Server: "",
};

const SSOInstance = axios.create({
  baseURL: process.env.REACT_APP_SSO_LOGIN_ORIGIN,
});

const APIInstance = axios.create({
  baseURL: process.env.REACT_APP_IAMT_BACK_END_SERVICE_PORT,
  headers,
});

const FileUploadInstance = axios.create({
  baseURL: process.env.REACT_APP_IAMT_BACK_END_SERVICE_PORT,
  headers: { ...headers, "Content-Type": "multipart/form-data" }
});

const DocsInstance = axios.create({
  baseURL: process.env.REACT_APP_DOCS_URL,
});

const axiosInstance = {
  SSOInstance,
  APIInstance,
  DocsInstance,
  FileUploadInstance,
};

export default axiosInstance;
