import { Fragment, useEffect, useState } from "react";
import SideMenu from "./SideMenu";
import HeaderApp from "./components/Header";
import { Outlet, useLocation } from "react-router-dom";
import FooterApp from "./components/Footer";
import { Spinner } from "react-bootstrap";
import APIService from "./services/api.service";
import useHttp from "./hooks/useHttp";
import AuditFilterProvider from "./components/AuditFilterProvider";

const mapNavlist = (permission: any, user: any, location: any): any[] => {
  return [
    permission.setup_client_menu?.view || permission.setup_project_menu?.view
      ? {
          name: "Setup",
          prefixIcon: "stewardship",
          suffixIcon: "down-chevron",
          opened: location.pathname.includes("setup"),
          children: [
            {
              name: permission.setup_client_menu?.view ? "Client" : "",
            },
            {
              name: permission.setup_user_menu?.view ? "Project" : "",
            },
          ],
        }
      : {
          name: "",
          itemClassName: "hideNavItem",
        },
    permission.audit_planning_menu?.view
      ? {
          name: "Audit Planning",
          prefixIcon: "news",
        }
      : {
          name: "",
          itemClassName: "hideNavItem",
        },
    permission.audit_execution_menu?.view ||
    permission.audit_execution_data_request_menu?.view
      ? {
          name: "Audit Execution",
          prefixIcon: "circle-checkmark",
          suffixIcon: "down-chevron",
          // itemClassName: "nav-disabled",
          opened: location.pathname.includes("audit-exection"),
          children: [
            permission.audit_execution_data_request_menu?.view
              ? {
                  name: "Data Request",
                }
              : {
                  name: "",
                  itemClassName: "hideNavItem",
                },
            {
              name: "Risk & Control Mapping",
              itemClassName: permission
                ?.audit_execution_risk_control_mapping_menu?.view
                ? ""
                : "hideNavItem",
            },
            {
              name: "Field Work",
              itemClassName: permission
                ?.audit_execution_field_work_menu?.view
                ? ""
                : "hideNavItem",
            },
          ],
        }
      : {
          name: "",
          itemClassName: "hideNavItem",
        },

    permission.reporting_menu?.view
      ? {
          name: "Reporting",
          prefixIcon: "venn-abc",
        }
      : {
          name: "",
          itemClassName: "hideNavItem",
        },
    permission.follow_up_dashboard_menu?.view ||
    permission.follow_up_observation_management_menu?.view
      ? {
          name: "Follow up",
          prefixIcon: "survey-checkmark",
          suffixIcon: "down-chevron",
          opened: location.pathname.includes("follow-up"),
          children: [
            {
              name: permission.follow_up_dashboard_menu?.view
                ? "Dashboard"
                : "",
            },
            {
              name: permission.follow_up_observation_management_menu?.view
                ? "Observation Management"
                : "",
            },
          ],
        }
      : {
          name: "",
          itemClassName: "hideNavItem",
        },
    permission.feedback_menu?.view
      ? {
          name: "Feedback",
          prefixIcon: "survey",
        }
      : {
          name: "",
          itemClassName: "hideNavItem",
        },
    permission.app_management_menu?.view ||
    permission.app_management_user_menu?.view ||
    permission.app_management_process_menu?.view ||
    permission.app_management_subprocess_menu?.view ||
    permission.app_management_department_menu?.view ||
    permission.app_management_type_menu?.view ||
    permission.app_management_location_menu?.view ||
    permission.app_management_alert_menu?.view ||
    permission.app_management_risks_controls_catalogue_menu?.view ||
    permission.app_management_audit_history_menu?.view
      ? {
          name: "App Management",
          prefixIcon: "survey-checkmark",
          suffixIcon: "down-chevron",
          opened: location.pathname.includes("audit-management"),
          children: [
            {
              name: permission.app_management_process_menu?.view
                ? "Process Management"
                : "",
              itemClassName: permission.app_management_process_menu?.view
                ? ""
                : "hideNavItem",
            },
            {
              name: permission.app_management_subprocess_menu?.view
                ? "Subprocess Management"
                : "",
              itemClassName: permission.app_management_subprocess_menu?.view
                ? ""
                : "hideNavItem",
            },
            {
              name: permission.app_management_department_menu?.view
                ? "Department Management"
                : "",
              itemClassName: permission.app_management_department_menu?.view
                ? ""
                : "hideNavItem",
            },
            {
              name: permission.app_management_type_menu?.view
                ? "Type Management"
                : "",
              itemClassName: permission.app_management_type_menu?.view
                ? ""
                : "hideNavItem",
            },
            {
              name: permission.app_management_location_menu?.view
                ? "Location Management"
                : "",
              itemClassName: permission.app_management_location_menu?.view
                ? ""
                : "hideNavItem",
            },
            {
              name: permission.app_management_user_menu?.view ? "User" : "",
              itemClassName: permission.app_management_user_menu?.view
                ? ""
                : "hideNavItem",
            },
            {
              name: permission.app_management_observation_catalogue_menu?.view
                ? "Observation Catalogue"
                : "",
              itemClassName: permission
                .app_management_observation_catalogue_menu?.view
                ? ""
                : "hideNavItem",
            },
            {
              name: permission.app_management_alert_menu?.view ? "Alert" : "",
              itemClassName: permission.app_management_alert_menu?.view
                ? ""
                : "hideNavItem",
            },
            {
              name: permission.app_management_risks_controls_catalogue_menu
                ?.view
                ? "Risk Catalogue"
                : "",
              itemClassName: permission
                .app_management_risks_controls_catalogue_menu?.view
                ? ""
                : "hideNavItem",
            },
            {
              name: permission.app_management_audit_history_menu?.view
                ? "Audit History"
                : "",
              itemClassName: permission.app_management_audit_history_menu?.view
                ? ""
                : "hideNavItem",
            },
          ],
        }
      : {
          name: "",
          itemClassName: "hideNavItem",
        },
    {
      name: "Logout",
      prefixIcon: "log-out",
    },
  ];
};

const Main = ({ user }: any) => {
  const [inactive, setInactive] = useState(false);
  const location = useLocation();
  const { sendRequest: getRoles } = useHttp();
  const [role, setRole] = useState("");
  const [permission, setPermission] = useState<any>({
    setup_menu: {
      view: true,
    },
    audit_planning_menu: {
      view: true,
    },
    app_management_catalogue_menu: {
      view: true,
    },
    app_management_menu: {
      view: true,
    },
    audit_process_menu: {
      view: true,
    },
    audit_subprocess_menu: {
      view: true,
    },
    audit_department_menu: {
      view: true,
    },
    audit_type_menu: {
      view: true,
    },
    audit_location_menu: {
      view: true,
    },
    catalogue_menu: {
      view: true,
    },
    audit_execution_menu: {
      view: true,
    },
    follow_up_menu: {
      view: true,
    },
    follow_up_observation_management_menu: {
      view: true,
    },
    follow_up_dashboard_menu: {
      view: true,
    },
    reporting_menu: {
      view: true,
    },
    feedback_menu: {
      view: true,
    },
    setup_client_menu: {
      view: true,
    },
    setup_project_menu: {
      view: true,
    },
    app_management_user_menu: {
      view: true,
    },
    setup_alert_menu: {
      view: true,
    },
    user_profile_menu: {
      view: true,
    },
    app_management_audit_history_menu: {
      view: true,
    },
  });
  const [navList, setNavList] = useState<any[]>([]);
  const [navItemsLoaded, setNavItemsLoaded] = useState(false);

  const fetchNavigationList = () => {
    getRoles(
      APIService.getRoles(),
      (json: any) => {
        setPermission(json.data.permissions);
        setRole(json.data.role);
        const list = mapNavlist(json.data.permissions, user, location);
        setNavList(list);
        setNavItemsLoaded(true);
        setRole(json.data.role);
      },
      (err) => {
        setNavItemsLoaded(true);
      }
    );
  };

  useEffect(() => {
    if (user?.isLoggedIn) {
      fetchNavigationList();
    }
  }, [user.isLoggedIn]);

  function collapse(data: any) {
    setInactive(data);
  }

  return (
    <Fragment>
      {(user.isLoading || !navItemsLoaded) && (
        <div className="loading">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      {!user.isLoading && navItemsLoaded && (
        <div className="wrapper">
          <SideMenu
            onCollapse={collapse}
            permission={permission}
            navList={navList}
            user={user}
            role={role}
          />
          <div
            id="content"
            className={inactive ? "content-body-collapsed" : "content-body"}
          >
            <AuditFilterProvider>
              <HeaderApp></HeaderApp>
              <Outlet />
              <FooterApp
                className={`container-footer ${
                  inactive ? "fixed-bottom sidenav" : "fixed-bottom width"
                }`}
              ></FooterApp>
            </AuditFilterProvider>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Main;
