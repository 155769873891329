import { Button, Modal } from "@appkit4/react-components";

const SessionTimeoutModal = ({user}: any) => {
  return (
    <Modal
      visible={user.isTimeout}
      title={"Session Timeout"}
      ariaLabel={"Session Timeout"}
      onCancel={() => {
        user.setIsTimeout(false);
        user.logout(user.userData?.userid);
      }}
      onClose={() => {
        user.setIsTimeout(false);
        user.logout(user.userData?.userid);
      }}
      modalStyle={{ width: "33.75rem" }}
      footerStyle={{
        paddingTop: "8px",
        marginTop: "-8px",
        minHeight: "64px",
      }}
      footer={
        <Button
          kind="primary"
          className="btn btn-primary btn-block login-button-color"
          onClick={() => {
            user.setIsTimeout(false);
            user.logout(user.userData?.userid);
          }}
        >
          Login
        </Button>
      }
    >
      <p>Your session is timed out, please login again!</p>
    </Modal>
  );
};

export default SessionTimeoutModal;
