import { Footer } from "@appkit4/react-components/footer";
import "../assets/css/Common.css";
import "./style.css";

const FooterApp = (props: any) => {
  const footerContent = `© ${new Date().getFullYear()} PwC. All rights reserved.PwC refers to India member firm and may sometimes refer to the PwC network.  Each member firm is a separate legal entity.Please see <a className='ap-link' href='https://www.pwc.com/structure'>www.pwc.com/structure</a> for further details.`;
  const footerType = "links";
  const footerLinks = [
    { name: "Imprint", href: "/imprints", target: "_self" },
    { name: "Credits", href: "/credits", target: "_self" },
    { name: "Cookies", href: "/cookies", target: "_self" },
  ];
  return (
    <div className={`bg-white pad-3 ${props.className} `}>
      <div className="footer-demo-wrapper">
        <Footer
          content={footerContent}
          type={footerType}
          links={footerLinks}
        ></Footer>
      </div>
    </div>
  );
};

export default FooterApp;
