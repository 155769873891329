import axiosInstance from "../models/axios.instance";
import { HttpMethods, URLS } from "../models/constants";
import { HttpConfig } from "../models/types";

const addFeedback = (userId: number, data: any): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.POST,
    url: `${URLS.ADD_FEEDBACK}/${userId}`,
    body: data,
  };
};

const getFeedback = (): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: URLS.FEEDBACK_LIST,
  };
};

const feedbackServices = {
  addFeedback,
  getFeedback,
};

export default feedbackServices;
