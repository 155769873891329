import { IControlAdd } from "../components/AuditExecution/Models/AuditExecutionModel";
import axiosInstance from "../models/axios.instance";
import { HttpMethods, URLS } from "../models/constants";
import { HttpConfig } from "../models/types";

const getControlForm = (scopeId: number, planId: number): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.AUDITEXECUTIONCONTROLS + scopeId}/${planId}`,
  };
};
const getControls = (scopeId?: number): HttpConfig => {
  const url = scopeId
    ? `${URLS.CONTROLLIST}?scope_id=${scopeId}`
    : URLS.CONTROLLIST;
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: url,
  };
};
const validateControls = (plan_id?: number): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.VALIDATEPLANCONTROLS}?plan_id=${plan_id}`,
  };
};

const addControlMapping = (form: IControlAdd): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.POST,
    url: URLS.ADDCONTROLMAPPING,
    body: form,
  };
};

const confirmControls = (form: any): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.POST,
    url: URLS.CONFIRMPLANCONTROLS,
    body: form,
  };
};

const editScopeControls = (plan_id: number, form: any): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.POST,
    url: `${URLS.EDITSCOPECONTROLS}?plan_id=${plan_id}`,
    body: form,
  };
};
const editScopeControlDetail = (form: any): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.POST,
    url: `${URLS.EDITSCOPECONTROLDETAIL}`,
    body: form,
  };
};

const fieldWorkAction = (body: any): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.POST,
    url: URLS.FIELDWORKACTION,
    body: body,
  };
};

const auditApprovalList = (planId?: number, projectId?: number): HttpConfig => {
  const url = () => {
    if (planId && planId > 0) {
      return `${URLS.AUDITAPPROVALLIST}?plan_id=${planId}`;
    } else {
      return projectId && projectId > 0
        ? `${URLS.AUDITAPPROVALLIST}?project_id=${projectId}`
        : URLS.AUDITAPPROVALLIST;
    }
  };
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: url(),
  };
};

const auditApprove = (body: any): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.POST,
    url: URLS.AUDITAPPOVAL,
    body: body,
  };
};

const auditExecutionServices = {
  getControlForm,
  getControls,
  validateControls,
  addControlMapping,
  confirmControls,
  editScopeControls,
  editScopeControlDetail,
  fieldWorkAction,
  auditApprovalList,
  auditApprove,
};

export default auditExecutionServices;
