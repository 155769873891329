import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import "../src/assets/css/Privacy.css";
const Privacy = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/privacy", { state: { module: "Privacy" } });
  }, []);
  return (
    <div className="entireContentPrivacy">
      <div className="parsys sectionpar alignment">
        <div className="text parbase section">
          <div></div>
          <div className="row">
            <div className="col-md-4"></div>
        <div className="col-md-6 headerText">Internal Audit Management Tool - Privacy Statement</div>
        <div className="col-md-2"></div>
        </div>
          <div className="text-component">
          <p>This privacy statement was last updated on  25 August, 2023.</p>

            <div className="headers">Introduction&nbsp;</div>
            <p>
              We are strongly committed to protecting personal data. This
              privacy statement describes why and how we collect, process and
              use personal data and provides information about individual’s
              rights in relation to personal data. It applies to personal data
              provided to us, both by individuals themselves or by others. We
              may use personal data provided to us for any of the purposes
              described in this privacy statement or as otherwise stated at the
              point of collection.&nbsp;<br></br>
              You have been given access to this Application solely in
              connection with your role at your organisation (your
              organisation). You may be a partner, member or employee of your
              organisation or you may be a contractor engaged by your
              organisation to provide it with services. Your employment or other
              agreement with your organisation regarding your role applies to
              your use of this Application.
            </p>
            <p>
              By using this Application and providing personal information to
              us, you acknowledge you have read this privacy statement. &nbsp;
            </p>
            <div className="headers">Definitions &nbsp;</div>
            <p>In this privacy statement we use the following terms:</p>
            <div>
              <span className="headers">Application</span> refers to the
              Internal Audit Management Tool 
            </div>&nbsp;
            <div>
              <span className="headers"> Personal data or personal information</span> is how we refer to information about you or information that identifies you as “personal data” or “personal information”.   &nbsp;
            </div>&nbsp;
            <div>
              <span className="headers">Processing  </span>is how we sometimes refer to the handling, sharing, processing, protecting or storing of your personal data. We process personal data in this Application for numerous purposes, as set out below.   &nbsp;
            </div>&nbsp;
            <div className="headers">
Definition of “us”, “we” and “our”
            </div>
            <div>
            As used in this privacy statement, “us”, “we” and “our” refers to the PwC network and/or one or more of its Member Firms that may process your personal information. Each Member Firm in the PwC network is a separate legal entity. See http:https://www.pwc.com/gx/en/about/office-locations.html for a list of countries and regions in which PwC Member Firms operate.   &nbsp;
            </div>&nbsp;   
            <div>
              <span className="headers">Processing of personal data </span>
            </div>
            <div>
            Our policy is to process only the personal data that we need and be transparent about why and how we process personal data. 
</div>&nbsp; 
<div>
The categories of personal data processed by us in relation to this Application are generally: &nbsp;
The categories of personal data processed by us in relation to this Application are generally:
<ol type="i">
<li>     name</li>
<li>	email address</li>
<li>	designation</li>
<li>	contact number</li>
</ol>
</div>
<div>
We may also process information about you when you use this Application, for example, logs of your activities on (for example, when you signed in and signed out, when upload or view a document, or when you post comments); logs and text you post in a comment, or logs showing what information you shared information, etc.. 


</div>&nbsp;
<div>
You may choose to provide additional information when you use this Application, including any content you contribute.  &nbsp;
</div>
<div>
We do not intend to process special categories of personal information through this Application and no special categories personal information is required for this Application to operate.  Special categories of personal information include race or ethnic origin; political opinions; religious or philosophical beliefs; trade union membership; physical or mental health; genetic data; biometric data; sexual life or sexual orientation; and criminal records. We ask that you not provide information of this nature about yourself or anyone else via this Application.  &nbsp;
</div>
          </div>
        </div>
      </div>&nbsp;
      <div className="parsys sectionpar">
        <div className="text parbase section">
          <div className="text-component privacy">
            <div className="headers">
            Our legal grounds for processing your personal data
            </div>
            <p>
            We rely on one or more of the following processing conditions:<br></br>
            </p>
            <ul>
              <li>
              	our legitimate interests in the effective delivery of information and services to you and in the effective and lawful operation of our businesses and the legitimate interests of our clients in receiving professional services from us as part of running their organisation (provided these do not interfere with your rights);
              </li>
              <li>
              	our legitimate interests in developing and improving our businesses, services and offerings and in developing our new technologies and offerings (provided these do not interfere with your rights);
              </li>
              <li>
              	our legitimate interests in maintaining the security of our and our client’s data, the quality of our services and maintaining our reputation;
              </li>
              <li>
              	to satisfy any requirement of law, regulation or professional body of which we are a member (for example, for some of our services, we have a legal obligation to provide the service in a certain way);
              </li>
              <li>
              	to perform our obligations under a contractual arrangement with you; or
               </li>
               <li>
               	where no other processing condition is available, if you have agreed to us processing your personal information for the relevant purpose.
                </li>
            </ul>
            <div className="headers">
            Use of personal data&nbsp;
</div>
When you provide personally identifiable information to us, we use it for the purposes for which it was provided to us as stated at the point where the personal data is ingested into the Application (or as obvious from the context provided at such point). We may also use your data in order to administer and manage the Application or to communicate with you in relation to the Application and get notifications or updates.  The Application does not store personally identifiable information for dissemination or sale to outside parties for consumer marketing purposes, or host mailings.
<p>We may use your personal information for any of the purposes described in this privacy statement, as stated at the point of where the personal data is ingested into the Application, or as obvious from the context of provided at such point, including complying with any requirements of law, regulation or a professional body of which we are a member.</p>
<p>As with any provider of professional services, we are subject to legal, regulatory and professional obligations. We need to keep certain records to demonstrate that our services are provided in compliance with those obligations and those records may contain personal data. </p>
<div className="headers">
Security
            </div>
            <p>We have implemented generally accepted standard of technology and operational security in order to protect personal information from loss, misuse, alteration or destruction. Only authorised persons are provided access to personal information; such individuals have agreed to maintain the confidentiality of this information.
Although we use appropriate security measures once we have received your personal data, the transmission of data over the internet (including by e-mail) is never completely secure. We endeavor to protect personal data, but we cannot guarantee the security of data transmitted to or by us. 
</p>
<div className="headers">
Cookies


            </div>
            <p>This Application does not store any cookies.</p>

            
            <div className="headers">Transfers of personal data</div>
            <div className="headers">Cross-border transfers</div>
            <p>
            If we process your personal information, your personal information may be transferred to and stored outside the country where you are located. This includes countries outside the European Economic Area (EEA) and countries that do not have laws that provide specific protection for personal information. 
            </p>
           
            
            <div className="headers">Other PwC Member Firms</div>
            <p>
              For details of PwC Member Firm locations, please see&nbsp;
              <u></u>
              <a href="/gx/en/about/office-locations.html">
                http://www.pwc.com/gx/en/about/office-locations.html
              </a>
              .
            </p>
            <p>
            We may share personal data with other PwC Member Firms where necessary in connection with the purposes described in this privacy statement. For example, when providing professional services to a client we may share personal information with PwC Member Firms in different territories that are involved in providing advice to that client. 
            </p>
            <div className="headers">Third Party Providers</div>
            <p>
            We may transfer or disclose the personal data shared with us, to third party contractors, subcontractors, and/or their subsidiaries and affiliates. Third parties support the PwC Network in providing its services and help provide, run and manage IT systems. Examples of third party contractors we use are providers of identity management, website hosting and management, data analysis, data backup, security and cloud storage services. The servers powering and facilitating our IT infrastructure are located in secure data centres around the world, and personal data may be stored in any one of them.
            </p>
            <p>
            The third party providers may use their own third party subcontractors that have access to personal data (sub-processors). It is our policy to use only third party providers that are bound to maintain appropriate levels of security and confidentiality, to process personal information only as instructed by us, and to flow those same obligations down to their sub-processors.
            </p>
            <div className="headers">Other disclosures</div>
            <p>
            We may also disclose personal information under the following circumstances:


            </p>
            <ul>
              <li>
              	with professional advisers, for example, law firms, as necessary to establish, exercise or defend our legal rights and obtain advice in connection with the running of our business. Personal data may be shared with these advisers as necessary in connection with the services they have been engaged to provide;
              </li>
              <li>	when explicitly requested by you;</li>
              <li>
              	when required to deliver publications or reference materials requested by you;
              </li>
              <li>
              	when required to facilitate conferences or events hosted by a third party;
              </li>
              <li>
              	To law enforcement, regulatory and other government agencies and to professional bodies, as required by and/or in accordance with applicable law or regulation. We may also review and use your personal information to determine whether disclosure is required or permitted.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="parsys sectionpar">
        <div className="linkexplorer list section">
          <div></div>

          <div className="dpe-component-wrapper link-explorer link-explorer--3-col component--col-default">
            <div className="container">
              <div className="row privacy">
                <div className="col-sm-12  link-explorer__intro">
                  <div className="headers">Retention of personal data</div>
                  <p className="link-explorer__intro-copy">
                    
We retain personal data processed by us for as long as is necessary for the purpose for which it was shared with us. 
Personal data may be held longer periods where extended retention periods are required by law or regulation and in order to establish, exercise or defend our legal rights.

                  </p>
                </div>
                </div>
                </div>    
        </div>
      </div>
      </div>
      <div className="parsys sectionpar">
        <div className="text parbase section">
          <div></div>

          <div className="text-component">
            <div className="headers">Individuals’ rights and how to exercise them</div>
            <p>
            You may have certain rights under laws of India in relation to the personal information we hold about you. In particular, you may have a legal right to:
            </p>
           
          </div>
        </div>
      </div>

      <div className="parsys sectionpar">
        <div className="text parbase section">
          <div></div>

          <div className="text-component">
            
            <ul>
              <li>
              	obtain confirmation as to whether we process personal data about you, receive a copy of your personal data and obtain certain other information about how and why we process your personal data 
              </li>
              <li>
              	the right to request for your personal data to be amended or rectified where it is inaccurate (for example, if you change your address) and to have incomplete personal data completed
              </li>
              <li>
              	The right to delete your personal data in the following cases:
                <ul>
                  <li>
                  	the personal data is no longer necessary in relation to the purposes for which they were shared with us and processed;
                  </li>
                  <li>
                  	our legal ground for processing is consent, you withdraw consent and we have no other lawful basis for the processing;
                  </li>
                  <li>
                  	our legal ground for processing is that the processing is necessary for legitimate interests pursued by us or a third party, you object to the processing and we do not have overriding legitimate grounds;
                  </li>
                  <li>
                  	you object to processing for direct marketing purposes;
                  </li>
                <li>	your personal data has been unlawfully processed; or</li>
                <li>	your personal data must be erased to comply with a legal obligation to which we are subject.</li>
                </ul>
              </li>
              <li>
              	The right to restrict personal data processing in the following cases:
                <ul>
                  <li>
                  	for a period enabling us to verify the accuracy of personal data where you contested the accuracy of the personal data; 
                  </li>
                  <li>
                  	your personal data have been unlawfully processed and you request restriction of processing instead of deletion;
                  </li>
                  <li>
                  	your personal data are no longer necessary in relation to the purposes for which they were shared with us and processed but the personal data is required by you to establish, exercise or defend legal claims; or
                  </li>
                  <li>
                  	for a period enabling us to verify whether the legitimate grounds relied on by us override your interests where you have objected to processing based on it being necessary for the pursuit of a legitimate interest identified by us.  
                  </li>
                </ul>
              </li>
              <li>
              	The right to object to the processing of your personal data in the following cases:
                <ul>
                  <li>
                  	our legal ground for processing is that the processing is necessary for a legitimate interest pursued by us or a third party; or
                  </li>
                  <li>	our processing is for direct marketing purposes.</li>
                </ul>
              </li>
              <li>
              	The right to data portability
                <ul>
                  <li>
                  	The right to receive your personal data provided by you to us and the right to send the data to another organisation (or ask us to do so if technically feasible) where our lawful basis for processing the personal data is consent or necessity for the performance of our contract with you and the processing is carried out by automated means.
                  </li>
                </ul>
              </li>
              <li>
              	The right to withdraw consent
                <ul>
                  <li>
                  	Where we process personal data based on consent, individuals have a right to withdraw consent at any time. We do not generally process personal data based on consent (as we can usually rely on another legal basis).
                  </li>
                </ul>
              </li>
            </ul>
            <div className="headers">
        Complaints
            </div>
            <p>If you have any questions or complaints about this privacy statement or the way your personal information is processed, or would like to exercise one of your rights set out above, please get in touch with us by clicking on the below link and submitting a request in this regard:</p>
            <p>https://privacyportal-eu.onetrust.com/webform/f13f2198-97ab-4c25-a5cd-0fca8ada2e21/b4e47c5a-ca49-419b-85de-0e1f7f6457eb  </p>
            <div className="headers">
            Changes to this privacy statement
            </div>
           <p>This privacy statement was last updated on 25 August, 2023. </p>
           <p>We may update this privacy statement at any times by publishing an updated version here. So you know when we make changes to this privacy statement, we will amend the revision date at the top of this page. The new modified or amended privacy statement will apply from that revision date. Therefore, we encourage you to review this privacy statement periodically to be information about how we are protecting your information. </p>
          </div>
        </div>
       
        <div className="h-60"></div>
      </div>
    </div>
  );
};
export default Privacy;
