export interface IUserRole {
  create: boolean;
  view: boolean;
  edit: boolean;
  delete: boolean;
}

export interface IUserContext {
  userData: IUserData;
  isLoading: boolean;
  isLoggedIn: boolean;
  isTimeout: boolean;
  login: Function;
  logout: Function;
  register: Function;
  setIsTimeout: Function;
  endSession: Function;
  setIsLoggedIn: Function;
  setUserData: Function;
}

export interface IUserData {
  department: any;
  designation: string;
  user_type: boolean;
  useremail: string;
  userid: number;
  username: string;
}

export const RouteSubModuleName: any = {
  Client: "setup_client_menu",
  Project: "setup_project_menu",
  User: "app_management_menu",
  "audit-planning": "audit_planning_menu",
  "report-home": "reporting_menu",
  dashboard: "follow_up_dashboard_menu",
  "observation-management": "follow_up_observation_management_menu",
  feedback: "feedback_menu",
  "user-profile": "user_profile_menu",
  "Alert":"app_management_alert_menu",
  "audit_execution_data_request": "audit_execution_data_request_menu",

  "process-management": "app_management_process_menu",
  "sub-management": "app_management_subprocess_menu",
  "department-management": "app_management_department_menu",
  "type-management": "app_management_type_menu",
  "app_catalogue": "app_management_observation_catalogue_menu",
  "location-management": "app_management_location_menu",
  "risk_catalogue": "app_management_risks_controls_catalogue_menu"
};

export enum SubModules {
  setup_client = "setup_client",
  setup_project = "setup_project",
  app_management_user = "app_management_user",
  audit_planning = "audit_planning",
  follow_up_observation = "follow_up_observation_to_follow_up",
  follow_up_management = "follow_up_management",
  reporting_excel_upload="reporting_excel_upload",
  reporting_observation="reporting_observation",
  reporting_report="reporting_report",
  setup_alert="app_management_alert",
  audit_execution_data_request= "audit_execution_data_request",

  audit_process="app_management_process",
  audit_subprocess="app_management_subprocess",
  audit_department="app_management_department",
  audit_type="app_management_type",
  app_catalogue= "app_management_observation_catalogue",
  audit_location="app_management_location",
  risk_catalogue= "app_management_risks_controls_catalogue"
}
