import { useState, createContext, useMemo } from "react";
import { io, Socket } from "socket.io-client";

export const SocketContext: any = createContext({})

export const SocketProvider = ({ children }: {children: React.ReactNode}) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const socketUrl: any = process.env.REACT_APP_IAMT_BACK_END_SERVICE_PORT_SOCKET

  const socketConnect = () => {
    if (!socket?.connected) {
      const newSocket = io(socketUrl, {
        path: '/sockets',
        transports: ['websocket'],
        autoConnect: false,
      });

      newSocket.connect();
      setSocket(newSocket);
    }
  };

  const socketDisconnect = () => {
    if (socket?.connected) {
      socket.disconnect();
      setSocket(null);
    }
  };

  const receiveNotification = (userid: any, setNotifications: any, setCounterlength: any) => {
    let tempCounter = 0
    socket?.emit("unread_notification",userid)
    socket?.on("response",(json: any) => {
      json.notifications.map((element: any) => {
        if(element.is_read === false){
          tempCounter = tempCounter + 1
        }
      })
      setNotifications(json.notifications)
      setCounterlength(tempCounter)
    })
  }

  const readNotification = (userid: any, notification_id: any, setNotifications: any, setCounterlength: any) =>{
    socket?.emit("read_notification",userid,notification_id)
    socket?.on("response_read",(json: any)=>{
      if(json.status === "success"){
        socket.emit("unread_notification",userid)
        socket.on("response",(json: any) => {
          let tempCounter = 0
          json.notifications.map((element: any) => {
            if(element.is_read === false){
              tempCounter = tempCounter + 1
            }
          })
          setNotifications(json.notifications)
          setCounterlength(tempCounter)
        })
      }
    })
  }
  
  const values = useMemo(
    () => ({
      socket,
      socketConnect,
      socketDisconnect,
      receiveNotification,
      readNotification
    }),
    [socket, socketConnect, socketDisconnect,receiveNotification,readNotification]
  );

  return (
    <SocketContext.Provider value = {values}>{children}</SocketContext.Provider>
  );
}
