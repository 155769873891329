import { Modal } from "@appkit4/react-components/modal";
import Alert from "./Alert";
const EditNotification = (props: any) => {
  const { isViewModal, editConfirm, editFormdata, setEditFormData } = props;

  return (
    <Modal
      visible={isViewModal}
      onCancel={props.handleEditModalConfirm}
      ariaLabel={"Notification"}
      modalStyle={{ width: "50rem" }}
      footerStyle={{ paddingTop: "8px", marginTop: "-8px", minHeight: "64px" }}
      header={""}
      icons={""}
      bodyStyle={{ minHeight: "92px" }}
    >
      <Alert
        editFormdata={editFormdata}
        setEditFormData={setEditFormData}
        editConfirm={editConfirm}
        isViewModal={isViewModal}
      />
    </Modal>
  );
};
export default EditNotification;
