import { AxiosInstance } from "axios";
import { URLS } from "../models/constants";

const addCreds = (req: any) => {
  req.headers.set(
    "Authorization",
    `Bearer ${localStorage.getItem("access_token")}`
  );
  req.headers.set("SessionID", localStorage.getItem("SessionID"));
};

export const interceptor = (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    async (req: any) => {
      if (
        !req?.url?.includes("login") &&
        !req.url?.includes("refresh") &&
        !req.url?.includes("corporate")
      ) {
        addCreds(req);
        return req;
      }
      return req;
    },
    (err: any) => {
      return Promise.reject(err);
    }
  );

  instance.interceptors.response.use(
    async (res) => {
      return res;
    },
    async (error) => {
      const status = error.response ? error.response.status : null;
      const orginalConfig = error.response.config;
      const refreshTokenKey = localStorage.getItem("refresh_token");
      const isNotRefresh = !error.request.responseURL.includes("refresh");
      if (
        status === 401 &&
        refreshTokenKey &&
        !orginalConfig._retry &&
        isNotRefresh
      ) {
        orginalConfig._retry = true;
        const newRes = await refreshToken(
          orginalConfig,
          instance,
          refreshTokenKey
        );
        return Promise.resolve(newRes);
      }
      if (status === 409) {
        localStorage.clear();
        window.location.reload();
      }
      return Promise.reject(error);
    }
  );
};

export async function refreshToken(
  orginalConfig: any,
  instance: any,
  refreshToken: any
) {
  try {
    localStorage.clear();
    const data = await instance.post(
      URLS.REFRESH,
      {},
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
          Session: localStorage.getItem("SessionID"),
        },
      }
    );
    localStorage.setItem("access_token", data.data.access_token);
    localStorage.setItem("refresh_token", data.data.refresh_token);
    return instance(orginalConfig);
  } catch (err) {
    return Promise.reject(err);
  }
}
