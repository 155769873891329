import { UserContext } from "./UserContext";
import { Fragment, useContext } from "react";
import { Outlet } from "react-router-dom";
import "./assets/css/Common.css";
import Main from "./Main";
import SessionTimeoutModal from "./SessionTimeout";
import { IUserContext } from "./models/user.model";
import { useIdleTimer } from "react-idle-timer";

function App() {
  const user: IUserContext = useContext(UserContext);
  
  const sessionEndWaring = () => {
    user.setIsTimeout(true);
  };

  useIdleTimer({
    onIdle: sessionEndWaring,
    timeout: 1000 * 60 * 30,
  });

  return (
    <Fragment>
      {!user.isLoggedIn && <Outlet />}
      {user.isLoggedIn && <Main user={user} />}
      <SessionTimeoutModal user={user} />
    </Fragment>
  );
}

export default App;
