import {
  Breadcrumb,
  BreadcrumbItem,
} from "@appkit4/react-components/breadcrumb";
import { useLocation, useNavigate } from "react-router-dom";
import "../assets/css/Header.css";
import { useEffect, useState, useContext, Fragment } from "react";
import { UserContext } from "../../src/UserContext";
import { SocketContext } from "../hooks/use-socket";
import AuditFilterBreadcrumb from "./AuditFilterBreadcrumb";
import { IUserContext } from "../models/user.model";

const HeaderApp = () => {
  const user: IUserContext = useContext(UserContext);
  const socket: any = useContext(SocketContext);
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [module, setModule] = useState("");
  const [subModule, setSubModule] = useState("");
  const [isFilter, setIsFilter] = useState({} as any);
  const [notifications, setNotifications] = useState([]);
  const [counterLength, setCounterLength] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    setModule(location.state?.module);
    setSubModule(location.state?.submodule);
    setIsFilter(location.state?.filter);
  }, [
    location.state?.module,
    location.state?.submodule,
    location.state?.filter,
  ]);

  useEffect(() => {
    socket.receiveNotification(
      user.userData.userid,
      setNotifications,
      setCounterLength
    );
    const interval = setInterval(() => {
      socket.receiveNotification(
        user.userData.userid,
        setNotifications,
        setCounterLength
      );
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const displayNotification = (value: any) => {
    let tempDate = value?.created_at?.split(" ")[0];
    let year = tempDate.split("-")[0];
    let month = tempDate.split("-")[1];
    let date = tempDate.split("-")[2];
    tempDate = date + "/" + month + "/" + year;
    return (
      <Fragment key={value.bell_notification_id}>
        <span
          className={value?.is_read ? "notificationRead" : "notification"}
          onClick={() =>
            notificationRead(user.userData.userid, value.bell_notification_id)
          }
          onKeyUp={(e) => e.preventDefault()}
        >
          {value?.is_read ? (
            <div>
              <div dangerouslySetInnerHTML={{ __html: value.content }} />
              <span className="dateStyle">{tempDate}</span>
            </div>
          ) : (
            <div className="row">
              <div className="col-1 unreadDot" />
              <div className="col-11">
                <div dangerouslySetInnerHTML={{ __html: value.content }} />
                <span className="dateStyleRead">{tempDate}</span>
              </div>
            </div>
          )}
        </span>
        <hr className="breakLine"></hr>
      </Fragment>
    );
  };

  const notificationRead = (userid: any, notificationId: any) => {
    socket.readNotification(
      userid,
      notificationId,
      setNotifications,
      setCounterLength
    );
  };

  const displaySingle = () => {
    return <span className="notificationRead">No notifications</span>;
  };

  return (
    <div className="header-row">
      <div>
        <Breadcrumb>
          {!user?.userData?.user_type ? (
            <BreadcrumbItem onClick={() => navigate("/")}>
              <span className="wt_label2" tabIndex={0}>
                {module ? (
                  <span className="Appkit4-icon icon-home-fill"></span>
                ) : (
                  "Dashboard"
                )}
              </span>
            </BreadcrumbItem>
          ) : (
            <></>
          )}
          {module ? (
            <BreadcrumbItem>
              <span className="wt_label2" tabIndex={0}>
                {module}
              </span>
            </BreadcrumbItem>
          ) : (
            <></>
          )}
          {subModule ? (
            <BreadcrumbItem>
              <span className="wt_label2" tabIndex={0}>
                <b>{subModule}</b>
              </span>
            </BreadcrumbItem>
          ) : (
            <></>
          )}
          {isFilter?.client && (
            <AuditFilterBreadcrumb
              handleChange={() => {}}
              setLoader={() => {}}
              userData={user.userData}
              options={isFilter}
            />
          )}
        </Breadcrumb>
      </div>

      <div className="top-icons">
        <span
          className="Appkit4-icon icon-notification-outline ap-font-16 top-icon cursor-pointer"
          title="notification"
          onClick={() => setOpen(!open)}
          onKeyUp={(e) => e.preventDefault()}
        ></span>
        {counterLength !== 0 ? <div className="counter"></div> : ""}
      </div>
      {open && (
        <div className="notifications">
          <div className="notificationHeader">
            <h3 className="notifyStyle">Notifications</h3>
            <div>
              <span className="count">Unread count: </span>
              {counterLength}
            </div>
          </div>
          <div id="scrollStyle" className="notification-content">
            {notifications?.length === 0
              ? displaySingle()
              : notifications.map((element: any) => {
                  return displayNotification(element);
                })}
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderApp;
