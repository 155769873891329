import axiosInstance from "../models/axios.instance";
import { HttpMethods, URLS } from "../models/constants";
import { HttpConfig } from "../models/types";

const getProcessSubList = (): HttpConfig =>{
    return {
        instance: axiosInstance.APIInstance,
        method: HttpMethods.GET,
        url: `${URLS.AUDIT_MANAGEMENT_SUBPROCESS_PROCESS_LIST}`
    }
}

const getSubProcessList = (value: any): HttpConfig =>{
    return {
        instance: axiosInstance.APIInstance,
        method: HttpMethods.GET,
        url: `${URLS.AUDIT_MANAGEMENT_SUBPROCESS_LIST + value}`
    }
}

const getProcessDropdownListSub = (): HttpConfig =>{
    return {
        instance: axiosInstance.APIInstance,
        method: HttpMethods.GET,
        url: `${URLS.AUDIT_MANAGEMENT_SUBPROCESS_PROCESS_DROPDOWN_LIST}`
    }
}

const getSubProcessDuplicate = (name: string): HttpConfig =>{
    return {
        instance: axiosInstance.APIInstance,
        method: HttpMethods.GET,
        url: `${URLS.AUDIT_MANAGEMENT_SUBPROCESS_DUPLICATE + name}`
    }
}

const editSubProcess = (ID: any ,data: any): HttpConfig => {
    return {
        instance: axiosInstance.APIInstance,
        method: HttpMethods.POST,
        url: `${URLS.AUDIT_MANAGEMENT_SUBPROCESS_EDIT + ID}`,
        body: data
    }
}

const addSubProcess = (data: any): HttpConfig => {
    return {
        instance: axiosInstance.APIInstance,
        method: HttpMethods.POST,
        url: `${URLS.AUDIT_MANAGEMENT_SUBPROCESS_ADD}`,
        body: data
    }
}

const auditManagementSubprocessServices = {
    getProcessSubList,
    getSubProcessList,
    getProcessDropdownListSub,
    getSubProcessDuplicate,
    editSubProcess,
    addSubProcess
}

export default auditManagementSubprocessServices