import "../src/assets/css/Credits.css";
import DataTable from "react-data-table-component";
import { Modal } from "@appkit4/react-components/modal";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import useHttp from "./hooks/useHttp";
import APIService from "./services/api.service";
import { Tooltip } from "@appkit4/react-components/tooltip";
import LoadSpinner from "./common/LoadSpinner";
function GetLabel(labelValue: any) {
  return (
    <Tooltip
      trigger="hover"
      position="top-left"
      distance={4}
      id="tooltipObsTitle"
      appendAfterTarget={true}
      content={labelValue}
    >
      <label className="readMore">{labelValue}</label>
    </Tooltip>
  );
}
function GetAction(row: any, onShowClick: any) {
  return (
    <div
      onClick={() => onShowClick(row.show_license)}
      onKeyDown={(e) => e.preventDefault()}
    >
      <p
        className={row.show_license === null ? "disabledAnchor" : "anchorText"}
      >
        <u>Show License</u>
      </p>
    </div>
  );
}
const Credits = () => {
  const navigate = useNavigate();
  const { sendRequest } = useHttp();
  useEffect(() => {
    navigate("/credits", { state: { module: "Credits" } });
    fetchCreditsData();
  }, []);
  const creditResponse = (json: any) => {
    if (Array.isArray(json)) {
      setData(json);
    }
    setIsLoading(false);
  };
  const fetchCreditsData = () => {
    sendRequest(APIService.getOSSList(), creditResponse);
  };

  const [isView, setIsView] = useState(false);
  const [modalData, setModalData] = useState("");
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const columns = [
    {
      name: "Name",
      selector: (row: any) => row.module_name,
    },
    {
      name: "License",
      selector: (row: any) => row.license,
    },
    {
      name: "Homepage",
      selector: (row: any) => row.repository,
      cell: (row: any) => GetLabel(row.repository),
    },
    {
      name: "",
      cell: (row: any) => GetAction(row, onShowClick),
    },
  ];
  const onShowClick = (row: any) => {
    setIsView(true);
    setModalData(row);
  };
  const onClose = () => {
    setIsView(false);
  };
  const customStyles = {
    rows: {
      style: {
        minHeight: "45px", // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: "45px",
      },
    },
    pagination: {
      style: {
        minHeight: "45px",
      },
    },
    headCells: {
      style: {
        backgroundColor: "#F2F2F2", // override the cell padding for head cells
        color: "black",
      },
    },
    cells: {
      style: {
        color: "#2D2D2D",
        fontSize: "14",
        width: "100px",
        backgroundColor: "#FFFFFF",
      },
    },
  };
  return (
    <div className="entireContent_Credits">
      <div className="headers">
        Credits
        <p className="bodyCredits">
          Portions of this program may use the following open source components
          (each on "OSS components")
        </p>
        {isLoading ? (
          <LoadSpinner $iscontent={true} />
        ) : (
          <DataTable
            data={data}
            columns={columns}
            customStyles={customStyles}
            pagination={true}
            responsive={true}
          />
        )}
      </div>
      <div className="endContent">
        <p className="bodyCredits">
          Please note, when a component is multi-licenses under a choice of OSS
          licenses, PwC chooses the permissive option, unless provided otherwise
          with the respective component, but recipients may select a license of
          their choosing in accordance with the multi-licenses. In the event of
          a conflict between the terms of any OSS license, or any additional
          terms (such as, for example, disclaimers) imposed thereby, and those
          of any license or other terms provided by PwC with the program (“PwC
          terms”), those of the OSS license shall prevail. “PwC” refers to the
          PwC network and/or one or more of its member firms, each of which is a
          separate legal entity. Please see www.pwc.com/structure for further
          details. Without limiting the foregoing, to the extent any such OSS
          license prohibits restrictions contained in the PwC terms with respect
          to an OSS component, such restrictions will not apply to such
          component. Notwithstanding anything to the contrary set forth in the
          PwC terms, PwC will have no liability with respect to claims of
          infringement or misappropriation of third party intellectual property
          rights arising from such OSS components. Any OSS component is provided
          on an “as is” basis, without warranty of any kind, either expressed,
          implied, or statutory, including, without limitation, warranties that
          it is free of defects, merchantable, fit for a particular purpose or
          non-infringing. The entire risk as to the quality and performance of
          any OSS component is with you. Should any OSS component prove
          defective in any respect, you alone assume the cost of any necessary
          servicing, repair, or correction. This disclaimer of warranty
          constitutes an essential part of this OSS licenses and PwC terms. No
          use of any OSS component is authorized under the OSS licenses or PwC
          terms except under this disclaimer. Any PwC terms that differ from the
          terms of the OSS licenses, including any additional warranties or
          service level, maintenance or other obligations that may be offered by
          PwC, are offered by PwC alone. Under no circumstances and under no
          legal theory, whether tort (including negligence), contract, or
          otherwise, shall any contributor to the OSS components, or anyone who
          distributes the OSS components as permitted by the OSS licenses, be
          liable to you for any direct, indirect, special, incidental, or
          consequential damages of any character including, without limitation,
          damages for lost profits, loss of goodwill, work stoppage, computer
          failure or malfunction, or any and all other commercial damages or
          losses, even if such party shall have been informed of the possibility
          of such damages. This limitation of liability shall not apply to
          liability for death or personal injury resulting from such party’s
          negligence to the extent applicable law prohibits such limitation.
          Some jurisdictions do not allow the exclusion or limitation of
          incidental or consequential damages, so this exclusion and limitation
          may not apply to you.
        </p>
      </div>
      <Modal
        visible={isView}
        onCancel={onClose}
        title="License"
        ariaLabel={"License"}
        modalStyle={{ width: "33.75rem" }}
        footerStyle={{
          paddingTop: "8px",
          marginTop: "-8px",
          minHeight: "64px",
        }}
        header={""}
        icons={""}
        bodyStyle={{ minHeight: "92px" }}
      >
        <div className="row">
          <div className="col">
            <p>{modalData}</p>
          </div>
        </div>
      </Modal>
      <div className="h-60"></div>
    </div>
  );
};

export default Credits;
