import axiosInstance from "../models/axios.instance";
import { HttpMethods, URLS } from "../models/constants";
import { HttpConfig } from "../models/types";

const login = (data: any): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.POST,
    url: URLS.LOGIN,
    body: data,
  };
};
const logout = (data: any, user_id?: number): HttpConfig => {
  const user = user_id ? `?user_id=${user_id}` : "";
  const url = URLS.LOGOUT + user;
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.POST,
    url: url,
    body: data,
  };
};
const register = (data: any): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.POST,
    url: URLS.REGISTER,
    body: data,
  };
};

const getUserInfo = (): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: URLS.USER_INFO,
  };
};

const getRoles = (): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: URLS.ROLES,
  };
};
const getFeaturePermissions = (userId: number): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: URLS.FEATURE_PERMISION + userId,
  };
};

const authServices = {
  login,
  logout,
  register,
  getUserInfo,
  getRoles,
  getFeaturePermissions,
};

export default authServices;
