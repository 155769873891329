import axiosInstance from "../models/axios.instance";
import { HttpMethods, URLS } from "../models/constants";
import { HttpConfig } from "../models/types";

const ssoLogin = (): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: URLS.SSO_LOGIN,
  };
};

const getSsoSession = (enpoint: string) => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: enpoint,
  };
};
const getSsoToken = (sessionId: string) => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.SSO_TOKEN}/${sessionId}`,
  };
};

const ssoServices = {
  ssoLogin,
  getSsoSession,
  getSsoToken,
};

export default ssoServices;
