import { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../src/assets/css/cookies.css";
import DataTable from "react-data-table-component";
const Cookies = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/cookies", { state: { module: "Cookies" } });
  }, []);
  const columns = [
    {
      name: "Cookie",
      selector: (row: any) => row.cookie,
    },
    {
      name: "Name",
      selector: (row: any) => row.name,
      width: "200px",
      wrap: true,
    },
    {
      name: "Purpose",
      selector: (row: any) => row.purpose,
      width: "300px",
      wrap: true,
    },
    {
      name: "Type",
      selector: (row: any) => row.type,
    },
    {
      name: "Duration",
      selector: (row: any) => row.duration,
    },
  ];
  const data = [
    {
      id: 1,
      cookie: "Federation",
      name: "ofisORIG_RPURN",
      purpose:
        "A session token that identifies what application URN the user attempted to access.",
      type: "First party session",
      duration: "Entire session",
    },
    {
      id: 2,
      cookie: "Federation",
      name: "ofisRPURN",
      purpose:
        "A session token that identifies what direct Relying Party URN the user was redirected from.",
      type: "First party session",
      duration: "Entire session",
    },
    {
      id: 3,
      cookie: "Federation",
      name: "ofisQUERY_STRING",
      purpose:
        "A session token that identifies the querystring of the request.",
      type: "First party session",
      duration: "Entire session",
    },
    {
      id: 4,
      cookie: "Federation",
      name: "ofisREMEMBER_ME",
      purpose: "A session token that identifies the users email address.",
      type: "First party session",
      duration: "Entire session",
    },
    {
      id: 5,
      cookie: "Federation",
      name: "ofisFED_IDPS",
      purpose:
        "A session token that contains the IdP URN the user logged in from.",
      type: "First party session",
      duration: "Entire session",
    },
    {
      id: 6,
      cookie: "Federation",
      name: "ofisFED_SPS",
      purpose:
        "A session token that contains a list of all Relying Party Application URN's the user is currently logged into.",
      type: "First party session",
      duration: "Entire session",
    },
    {
      id: 7,
      cookie: "Federation",
      name: "ofisIDP_ASSERTION_SUBJECT_NAME",
      purpose:
        "A session token that identifies the user's subject name attribute/value from the IdP logged in from.",
      type: "First party session",
      duration: "Entire session",
    },
    {
      id: 8,
      cookie: "Federation",
      name: "ofisRETURN_URL",
      purpose:
        "A session token that identifies where to redirect the user after federation has been completed.",
      type: "First party session",
      duration: "Entire session",
    },
    {
      id: 9,
      cookie: "Federation",
      name: "ofisTARGET_URL",
      purpose:
        "A session token that identifies where to redirect the user after federation has been completed.",
      type: "First party session",
      duration: "Entire session",
    },
    {
      id: 10,
      cookie: "Federation",
      name: "ofisEVENT_SLA",
      purpose:
        "A session token that identifies SLA start times of federation events.",
      type: "First party session",
      duration: "Entire session",
    },
    {
      id: 11,
      cookie: "Federation",
      name: "ofisHRD",
      purpose:
        "A persistent token that identifies HRD URN the user choose to remember during the HomeRealmDiscover process.",
      type: "First Party Persistent",
      duration: "9,999 days",
    },
    {
      id: 12,
      cookie: "Federation",
      name: "OFIS.ASPXAUTH",
      purpose:
        "A session token that represents the Logged in user within the current ASP.NET session.",
      type: "First party session",
      duration: "Entire session",
    },
    {
      id: 13,
      cookie: "Federation",
      name: "ASP.NET_Sessionid",
      purpose: "A session token that represents the ASP.NET session.",
      type: "First party session",
      duration: "Entire session",
    },
  ];
  const customStyles = {
    rows: {
      style: {
        minHeight: "45px", // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: "45px",
      },
    },
    pagination: {
      style: {
        minHeight: "45px",
      },
    },
    headCells: {
      style: {
        backgroundColor: "#F2F2F2", // override the cell padding for head cells
        color: "black",
      },
    },
    cells: {
      style: {
        color: "#2D2D2D",
        fontSize: "14",
        width: "100px",
        backgroundColor: "#FFFFFF",
      },
    },
  };
  return (
    <Fragment>
      <div className="entireContent">
        <div className="headers">
          Cookies
          <p className="bodyCookie">
            Cookies are small text files that are placed on your computer by the
            websites that you visit. They are widely used in order to make
            websites work, or work more efficiently, as well as to provide
            information to the owners of the site. The use of cookies is now
            standard for most websites. If you are uncomfortable with the use of
            cookies, you can manage and control them through your browser,
            including removing cookies by deleting them from your ‘browser
            history’ (cache) when you leave the site.
          </p>
        </div>
        <div className="headers">
          <b>Types of Cookies: </b>
          <p className="bodyCookie">
            'Session' cookies remain in your browser during your browser session
            only, ie until you leave the website.
          </p>
          <p className="bodyCookie">
            'Persistent' cookies remain in your browser after the session
            (unless deleted by you).
          </p>
          <p className="bodyCookie">
            'Performance' cookies collect information about your use of the
            website, such as webpages visited and any error messages; they do
            not collect personally identifiable information, and the information
            collected is aggregated such that it is anonymous. Performance
            cookies are used to improve how a website works.
          </p>
          <p className="bodyCookie">
            'Functionality' cookies allow the website to remember any choices
            you make about the website (such as changes to text size, customised
            pages) or enable services such as commenting on a blog.
          </p>
        </div>
        <div className="headers">
          <b>Use of cookies by PwC: </b>
          <p className="bodyCookie">
            The following table explains the way in which we use cookies on this
            website.
          </p>
          <DataTable
            data={data}
            columns={columns}
            customStyles={customStyles}
            pagination={true}
            responsive={true}
          />
        </div>
        <div className="endContent">
          <p className="bodyCookie">
            The legal basis for the use of technically necessary cookies is
            Section 25 par. 2 number 2 of the German
            Telecommunication-Telemedia- Act ("TTDSG") and on the basis of Art.
            6 par. 1 f) GDPR to protect our legitimate interests. Our legitimate
            interests here lie in particular in being able to provide you with a
            technically optimized and user-friendly website designed to meet
            your needs, as well as to ensure the security of our systems.
          </p>
          <p className="bodyCookie">
            By using this website, you agree that we can place these cookies on
            your computer/device for the above purposes.
          </p>
        </div>
      </div>
      <div className="h-60"></div>
    </Fragment>
  );
};
export default Cookies;
