import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  useContext,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useHttp from "./hooks/useHttp";
import APIService from "./services/api.service";
import { SocketContext } from "./hooks/use-socket";
import { IUserContext } from "./models/user.model";

export const UserContext: any = createContext("");

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [userData, setUserData] = useState<any>({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { sendRequest: loginAPI } = useHttp();
  const { sendRequest: userInfoAPI } = useHttp();
  const { sendRequest: registerAPI } = useHttp();
  const { sendRequest: logoutAPI } = useHttp();
  const [isTimeout, setIsTimeout] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const socket: any = useContext(SocketContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    const isSSO = location.pathname.includes("ssoSuccess");
    if (!token) {
      endSession();
      setIsLoggedIn(false);
      setIsLoading(false);
      if (!isSSO) {
        navigate("/login");
      }
    } else {
      userInfoAPI(APIService.getUserInfo(), getUserReposne, (err) => {
        if (err?.response?.status === 409) {
          logout(err.response.data?.detail?.user_id);
        }
        setIsLoading(false);
      });
    }
  }, []);

  const register = (username: any, email: any, password: any) => {
    return new Promise((result, resolve) => {
      registerAPI(
        APIService.register({
          username,
          email,
          password,
        }),
        result,
        resolve
      );
    });
  };

  const login = (data: any) => {
    return new Promise((resolve, reject) => {
      loginAPI(
        APIService.login(data),
        (response) => {
          if (response.access_token) {
            localStorage.setItem("SessionID", response.SessionID);
            localStorage.setItem("refresh_token", response.refresh_token);
            localStorage.setItem("access_token", response.access_token);
            userInfoAPI(
              APIService.getUserInfo(),
              (data) => loginReposne(data, resolve, response.DefaultPath),
              (error) => {
                reject(error);
              }
            );
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  };

  const loginReposne = (data: any, resolve: any, defaultRoute: any) => {
    setUserData(data.UserDetails);
    setIsLoggedIn(true);
    if (data?.UserDetails?.user_type || data?.UserDetails?.is_admin) {
      navigate(defaultRoute?.path, {
        state: {
          module: defaultRoute?.module,
          submodule: defaultRoute?.submodule,
          filter:
            defaultRoute?.module === "Audit Planning"
              ? { client: true, project: true, plan: false }
              : { client: false, project: false, plan: false },
        },
      });
    } else {
      navigate("/");
    }
    resolve("login successful");
    socket.socketConnect();
  };

  const getUserReposne = (data: any) => {
    if (data.UserDetails === "Autheticating user failed") {
      setIsLoading(false);
      logout();
    } else {
      setIsLoading(false);
      setUserData(data?.UserDetails);
      setIsLoggedIn(true);
      socket.socketConnect();
    }
  };

  const endSession = () => {
    localStorage.clear();
  };
  const logoutResponse = (data: any) => {
    if (data?.UserLogout === "Successful") {
      endSession();
      setUserData({});
      endSession();
      setIsLoggedIn(false);
      socket.socketDisconnect();
      setIsLoading(false);
      navigate("/login");
    }
  };
  const logout = (user_id?: number) => {
    setIsLoading(true);
    logoutAPI(APIService.logout({}, user_id), logoutResponse, (err) => {
      setUserData({});
      endSession();
      setIsLoggedIn(false);
      setIsLoading(false);
      socket.socketDisconnect();
      navigate("/login");
    });
  };

  const values = useMemo(
    (): IUserContext => ({
      userData,
      login,
      logout,
      register,
      isLoading,
      isLoggedIn,
      isTimeout,
      setIsTimeout,
      endSession,
      setIsLoggedIn,
      setUserData,
    }),
    [userData, isLoading, isLoggedIn, isTimeout]
  );
  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};
