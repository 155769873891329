import axiosInstance from "../models/axios.instance";
import { HttpMethods, URLS } from "../models/constants";
import { HttpConfig } from "../models/types";

const getProcessTemplateDoc = (name: string): HttpConfig => {
    return {
        instance: axiosInstance.APIInstance,
        method: HttpMethods.GET,
        headers: {
            responseType: "blob"
        },
        url: `${URLS.AUDIT_MANAGEMENT_PROCESS_TEMPLATE_DOWNLOAD + name}`
    }
};

const getProcessList = (): HttpConfig =>{
    return {
        instance: axiosInstance.APIInstance,
        method: HttpMethods.GET,
        url: `${URLS.AUDIT_MANAGEMENT_PROCESS_LIST}`
    }
}

const getProcessDuplicate = (name: string): HttpConfig =>{
    return {
        instance: axiosInstance.APIInstance,
        method: HttpMethods.GET,
        url: `${URLS.AUDIT_MANAGEMENT_PROCESS_DUPLICATE + name}`
    }
}

const editProcess = (id:number, data: any): HttpConfig => {
    return {
        instance: axiosInstance.APIInstance,
        method: HttpMethods.POST,
        url: `${URLS.AUDIT_MANAGEMENT_PROCESS_EDIT + id}`,
        body: data
    }
}

const addProcess = (data: any): HttpConfig => {
    return {
        instance: axiosInstance.APIInstance,
        method: HttpMethods.POST,
        url: `${URLS.AUDIT_MANAGEMENT_PROCESS_ADD}`,
        body: data
    }
}

const addProcessBulk = (data: any): HttpConfig => {
    return {
        instance: axiosInstance.APIInstance,
        method: HttpMethods.POST,
        url: `${URLS.AUDIT_MANAGEMENT_PROCESS_ADD_BULK}`,
        body: data
    }
}

const auditManagementProcessServices = {
    getProcessTemplateDoc,
    getProcessList,
    getProcessDuplicate,
    editProcess,
    addProcess,
    addProcessBulk
}

export default auditManagementProcessServices