import React from "react";
type myprops=any;
type mystate={error: null, errorInfo: null};
class ErrorBoundary extends React.Component <myprops,mystate> {
  constructor(props:any) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error:any, errorInfo:any) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div className="error-container">
          <h2>An Error Has Occurred</h2>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;