import axiosInstance from "../models/axios.instance";
import { HttpMethods, URLS } from "../models/constants";
import { HttpConfig } from "../models/types";

const getTypeTemplateDoc = (name: string): HttpConfig => {
    return {
        instance: axiosInstance.APIInstance,
        method: HttpMethods.GET,
        headers: {
            responseType: "blob"
        },
        url: `${URLS.AUDIT_MANAGEMENT_TYPE_TEMPLATE_DOWNLOAD + name}`
    }
};

const getTypeList = (): HttpConfig =>{
    return {
        instance: axiosInstance.APIInstance,
        method: HttpMethods.GET,
        url: `${URLS.AUDIT_MANAGEMENT_TYPE_LIST}`
    }
}

const getTypeDuplicate = (name: string): HttpConfig =>{
    return {
        instance: axiosInstance.APIInstance,
        method: HttpMethods.GET,
        url: `${URLS.AUDIT_MANAGEMENT_TYPE_DUPLICATE + name}`
    }
}

const editType = (id:number, data: any): HttpConfig => {
    return {
        instance: axiosInstance.APIInstance,
        method: HttpMethods.POST,
        url: `${URLS.AUDIT_MANAGEMENT_TYPE_EDIT + id}`,
        body: data
    }
}

const addType = (data: any): HttpConfig => {
    return {
        instance: axiosInstance.APIInstance,
        method: HttpMethods.POST,
        url: `${URLS.AUDIT_MANAGEMENT_TYPE_ADD}`,
        body: data
    }
}

const addTypeBulk = (data: any): HttpConfig => {
    return {
        instance: axiosInstance.APIInstance,
        method: HttpMethods.POST,
        url: `${URLS.AUDIT_MANAGEMENT_TYPE_ADD_BULK}`,
        body: data
    }
}

const auditManagementTypeServices = {
    getTypeTemplateDoc,
    getTypeList,
    getTypeDuplicate,
    editType,
    addType,
    addTypeBulk
}

export default auditManagementTypeServices;