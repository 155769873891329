import axiosInstance from "../models/axios.instance";
import { interceptor } from "../interceptors/axios.interceptor";
import clientServices from "./setup.client.service";
import userTestService from "./user.test.service";
import authServices from "./auth.service";
import userServices from "./audit.management.user.service";
import auditServices from "./audits.service";
import projectServices from "./setup.project.service";
import followupServices from "./followup.service";
import feedbackServices from "./feedback.service";
import reportingServices from "./reporting.service";
import ssoServices from "./sso.service";
import auditManagementDepartmentServices from "./audit.management.department.service";
import auditManagementProcessServices from "./audit.management.process.service";
import auditManagementTypeServices from "./audit.management.type.service";
import auditManagementSubprocessServices from "./audit.management.subprocess.service";
import auditManagementCatalogueServices from "./audit.management.catalogue.service";
import auditManagementAuditTrackerService from "./audit.management.audit.tracker.service";
import auditManagementLocationService from "./audit.management.location.service";
import auditExecutionDataRequest from "./audit.execution.data.request.service";
import auditExecutionServices from "./audit.execution.service";
import auditManagementRiskCatalogueServices from "./audit.management.risk.catalogue.service";
import { HttpConfig } from "../models/types";
import { HttpMethods, URLS } from "../models/constants";
import appManagementAlertServices from "./app.management.alert.services";
import dashboardService from "./dashboard.service";

interceptor(axiosInstance.APIInstance);

interceptor(axiosInstance.FileUploadInstance);

const getOSSList = (): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: URLS.OSS_LIST,
  };
};

const APIService = {
  getOSSList,
  ...authServices,
  ...auditServices,
  ...clientServices,
  ...projectServices,
  ...userServices,
  ...followupServices,
  ...feedbackServices,
  ...reportingServices,
  ...userTestService,
  ...ssoServices,
  ...appManagementAlertServices,
  ...auditManagementDepartmentServices,
  ...auditManagementProcessServices,
  ...auditManagementTypeServices,
  ...auditManagementSubprocessServices,
  ...auditManagementCatalogueServices,
  ...auditExecutionDataRequest,
  ...auditExecutionServices,
  ...auditManagementAuditTrackerService,
  ...auditManagementRiskCatalogueServices,
  ...auditManagementLocationService,
  ...dashboardService,
};

export default APIService;
