import { Input } from "@appkit4/react-components/field";
import "../../../assets/css/Common.css";
import { TextEditor } from "@appkit4/react-text-editor";
import "@appkit4/react-text-editor/dist/appkit4-react-texteditor.min.css";

const Alert = ({ editFormdata }: any) => {
  const sampleConfig = {
    toolbar: [
      "fontFamily",
      "fontSize",
      "bold",
      "italic",
      "strikethrough",
      "underline",
      "bulletedList",
      "numberedList",
      "indent",
      "outdent",
      "alignment:left",
      "alignment:center",
      "alignment:right",
      "alignment:justify",
      "link",
      "undo",
      "redo",
    ],
  };

  return (
    <div className="mt-2 feedbackAlignment">
      <h6>Client : {editFormdata?.client}</h6>
      <h6>Project : {editFormdata?.project}</h6>
      <form>
        <div className="row mt-3">
          <div className="col-md-6">
            <Input
              type={"text"}
              title={"Module "}
              className="textFieldWidth"
              name="Module"
              value={editFormdata?.module}
              placeholder="Module "
              disabled
            ></Input>
          </div>
          <div className="col-md-6">
            <Input
              type={"text"}
              title={"SubModule "}
              className="textFieldWidth"
              name="SubModule"
              value={editFormdata?.sub_module}
              placeholder="SubModule "
              required={true}
              disabled
            ></Input>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-6">
            <Input
              type={"text"}
              title={"Operation "}
              className="textFieldWidth"
              name="Operation"
              value={editFormdata?.operation}
              placeholder="Operation "
              disabled
            ></Input>
          </div>
          <div className="col-md-6">
            <Input
              type={"text"}
              title={"Search type "}
              className="textFieldWidth"
              name="Search type"
              value={editFormdata?.notification_type}
              placeholder="Search type "
              required={true}
              disabled
            ></Input>
          </div>
        </div>
        {editFormdata?.notification_type_id != "1" ? (
          <div className="row mt-3">
            <div className="col-md-6">
              <Input
                type={"text"}
                title={"Search Email type "}
                className="textFieldWidth"
                name="Search Email type"
                value={editFormdata?.sub_type}
                placeholder="Search Email type "
                disabled
              ></Input>
            </div>
            <div className="col-md-6">
              <Input
                type={"text"}
                title={"Subject "}
                className="textFieldWidth"
                name="Subject"
                value={editFormdata.subject}
                placeholder="Subject "
                disabled
              ></Input>
            </div>
          </div>
        ) : (
          ""
        )}

        {editFormdata?.notification_type_id != "1" ? (
            <div className="row mt-3">
              <div className="col-md-6">
                <Input
                  type={"number"}
                  title={"How many days "}
                  className="textFieldWidth"
                  name="HowManyDays"
                  value={editFormdata.days}
                  disabled
                ></Input>
              </div>
              <div className="col-md-6">
                <Input
                  type={"number"}
                  title={"Frequency in days "}
                  className="textFieldWidth"
                  name="Frequency in days"
                  value={editFormdata.frequency}
                  disabled
                ></Input>
              </div>
            </div>
        ) : (
          ""
        )}

        <div className="row mt-3">
          <div className="col-md-12">
            <div className="doc-editor-container">
              <TextEditor
                data={editFormdata?.content}
                config={sampleConfig}
                disabled
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Alert;
