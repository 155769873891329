import axiosInstance from "../models/axios.instance";
import { HttpMethods, URLS } from "../models/constants";
import { HttpConfig } from "../models/types";

const getDesignations = (): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: URLS.DESIGNATION,
  };
};

const getPractices = (): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: URLS.PRACTICE,
  };
};
const getRole = (): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: URLS.Role,
  };
};
const getUsers = (): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: URLS.USER_LIST,
  };
};
const getUserDetail = (userId: number): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: URLS.USER_DETAIL + userId,
  };
};
const getUserByEmail = (emailId: string): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: URLS.USER_SEARCH + emailId,
  };
};

const getDuplicateUser = (email: string): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: URLS.DUPLICATE_USER + email,
  };
};

const registerUser = (data: any): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.POST,
    url: URLS.USER_REGISTER,
    body: data,
  };
};
const deleteUser = (id: number, data: any): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.POST,
    url: URLS.DELETE_USER + id,
    body: data,
  };
};
const editUser = (id: number, data: any): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.POST,
    url: URLS.EDIT_USER + id,
    body: data,
  };
};

const userServices = {
  getDesignations,
  getDuplicateUser,
  getPractices,
  getUsers,
  getUserDetail,
  getUserByEmail,
  registerUser,
  deleteUser,
  editUser,
  getRole,
};

export default userServices;
