import axiosInstance from "../models/axios.instance";
import { HttpMethods, URLS } from "../models/constants";
import { HttpConfig } from "../models/types";

const addRiskCatalogueBulk = (data: any): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.POST,
    url: `${URLS.AUDIT_MANAGEMENT_RISK_CATALOGUE_ADD_BULK}`,
    body: data,
  };
};
const getRiskCatalogueTemplateDoc = (): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    headers: {
      responseType: "blob",
    },
    url: `${URLS.AUDIT_MANAGEMENT_RISK_CATALOGUE_TEMPLATE_DOWNLOAD}`,
  };
};

const getRisks = (): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: URLS.AUDIT_MANAGEMENT_RISK_CATALOGUE_OVERVIEW,
  };
};

const auditManagementRiskCatalogueServices = {
  addRiskCatalogueBulk,
  getRiskCatalogueTemplateDoc,
  getRisks,
};

export default auditManagementRiskCatalogueServices;
