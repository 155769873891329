import axiosInstance from "../models/axios.instance";
import { HttpMethods, URLS } from "../models/constants";
import { HttpConfig } from "../models/types";

const auditTrackerData = (pageNumber: number, pageSize: number, startDate: any, endDate: any): HttpConfig => {
    return {
        instance: axiosInstance.APIInstance,
        method: HttpMethods.GET,
        url: `${URLS.AUDIT_MANAGEMENT_AUDIT_TRACKER + pageNumber + "&page_size=" + pageSize + "&start_date=" + startDate + "&end_date=" + endDate}`
    }
}

const auditTrackerDataWithoutDate = (pageNumber: number, pageSize: number): HttpConfig => {
    return {
        instance: axiosInstance.APIInstance,
        method: HttpMethods.GET,
        url: `${URLS.AUDIT_MANAGEMENT_AUDIT_TRACKER + pageNumber + "&page_size=" + pageSize}`
    }
}

const auditManagementAuditTrackerService = {
    auditTrackerData,
    auditTrackerDataWithoutDate
}

export default auditManagementAuditTrackerService