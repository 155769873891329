import { createContext, useMemo, useState } from "react";
import { IDropdown } from "../common/CommonInterface";

export const AuditFilterContext = createContext({} as any);

export interface IFilterContext {
  filters: any;
  setFilters: any;
  clientList: IDropdown[];
  setClientList: any;
  projectList: any[];
  setProjectList: any;
  planList: IDropdown[];
  setPlanList: any;
  isProjectFilter: boolean;
  setIsProjectFilter: any;
}

const AuditFilterProvider = ({ children }: any) => {
  const [filters, setFilters] = useState({} as any);
  const [clientList, setClientList] = useState<Array<IDropdown>>([]);
  const [projectList, setProjectList] = useState<Array<IDropdown>>([]);
  const [planList, setPlanList] = useState<Array<IDropdown>>([]);
  const [isProjectFilter, setIsProjectFilter] = useState(false);

  const values = useMemo(
    (): IFilterContext => ({
      filters,
      setFilters,
      clientList,
      setClientList,
      projectList,
      setProjectList,
      planList,
      setPlanList,
      isProjectFilter,
      setIsProjectFilter,
    }),
    [filters, clientList, projectList, planList, isProjectFilter]
  );
  return (
    <AuditFilterContext.Provider value={values}>
      {children}
    </AuditFilterContext.Provider>
  );
};

export default AuditFilterProvider;
