import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@appkit4/styles/appkit.min.css";
import "@appkit4/react-components/dist/styles/appkit4-react.min.css";
import { UserProvider } from "./UserContext";
import ErrorBoundary from "./common/ErrorBoundary";
import MainRoute from "./MainRoute";
import { SocketProvider } from "./hooks/use-socket";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Router>
    <SocketProvider>
    <UserProvider>
      <ErrorBoundary>
        <MainRoute />
      </ErrorBoundary>
    </UserProvider>
    </SocketProvider>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
