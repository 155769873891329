import axiosInstance from "../models/axios.instance";
import { HttpMethods, URLS } from "../models/constants";
import { HttpConfig } from "../models/types";

const getTimelineData = (clientId: number, projectId: number): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.TIMELINEDATA}?client_id=${clientId}&project_id=${projectId}`,
  };
};

const getProjectOverview = (
  clientId: number,
  projectId: number
): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.PROJECTOVERVIEW}?client_id=${clientId}&project_id=${projectId}`,
  };
};
const getControlStatus = (
  clientId: number,
  projectId: number,
  planId: number
): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.CONTROLSTATUS}?plan_id=${planId}&client_id=${clientId}&project_id=${projectId}`,
  };
};
const getProcessWiseControl = (
  clientId: number,
  projectId: number,
  planId: number
): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.PROCESSWISEPROGRESS}?plan_id=${planId}&client_id=${clientId}&project_id=${projectId}`,
  };
};
const getAuditorWiseControl = (
  clientId: number,
  projectId: number,
  planId: number
): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.AUDITORWISEPROGRESS}?plan_id=${planId}&client_id=${clientId}&project_id=${projectId}`,
  };
};
const getDataRequestStatus = (
  clientId: number,
  projectId: number,
  planId: number
): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.DATAREQUESTSTATUS}?plan_id=${planId}&client_id=${clientId}&project_id=${projectId}`,
  };
};
const getSubProcessAgeing = (
  clientId: number,
  projectId: number,
  planId: number
): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.DATAREQUESTSUBPROCESSAGEING}?plan_id=${planId}&client_id=${clientId}&project_id=${projectId}`,
  };
};
const getAuditeeAgeing = (
  clientId: number,
  projectId: number,
  planId: number
): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.DATAREQUESTAUDITEEAGEING}?plan_id=${planId}&client_id=${clientId}&project_id=${projectId}`,
  };
};
const getReportingControlTestingStatus = (
  clientId: number,
  projectId: number,
  planId: number
): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.REPORTINGCONTROLSTATUS}?plan_id=${planId}&client_id=${clientId}&project_id=${projectId}`,
  };
};
const getReportingProcessWiseRating = (
  clientId: number,
  projectId: number,
  planId: number
): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.REPORTINGPROCESSWISERATING}?plan_id=${planId}&client_id=${clientId}&project_id=${projectId}`,
  };
};
const getReportingProcessWiseGapCategory = (
  clientId: number,
  projectId: number,
  planId: number
): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.REPORTINGPROCESSWISEGAPCATEGORYRATING}?plan_id=${planId}&client_id=${clientId}&project_id=${projectId}`,
  };
};
const getReportingRatingCountByProcess = (
  clientId: number,
  projectId: number,
  planId: number
): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.REPORTINGRATINGCOUNTBYPROCESS}?plan_id=${planId}&client_id=${clientId}&project_id=${projectId}`,
  };
};
const getReportingRatingCount = (
  clientId: number,
  projectId: number,
  planId: number
): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.REPORTINGRATINGCOUNT}?plan_id=${planId}&client_id=${clientId}&project_id=${projectId}`,
  };
};
const getFollowupObservationsAuditStatus = (
  clientId: number,
  projectId: number,
  planId: number
): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.FOLLOWUPOBSERVATIONSAUDITSTATUS}?plan_id=${planId}&client_id=${clientId}&project_id=${projectId}`,
  };
};
const getFollowupObservationsRating = (
  clientId: number,
  projectId: number,
  planId: number
): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.FOLLOWUPOBSERVATIONSRATING}?plan_id=${planId}&client_id=${clientId}&project_id=${projectId}`,
  };
};
const getFollowupObservationsByActionOwner = (
  clientId: number,
  projectId: number,
  planId: number
): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.FOLLOWUPOBSERVATIONSBYACTIONOWNER}?plan_id=${planId}&client_id=${clientId}&project_id=${projectId}`,
  };
};
const getFollowupObservationsByAgeing = (
  clientId: number,
  projectId: number,
  planId: number
): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.FOLLOWUPOBSERVATIONSAGEING}?plan_id=${planId}&client_id=${clientId}&project_id=${projectId}`,
  };
};

const dashboardService = {
  getTimelineData,
  getProjectOverview,
  getControlStatus,
  getProcessWiseControl,
  getAuditorWiseControl,
  getDataRequestStatus,
  getSubProcessAgeing,
  getAuditeeAgeing,
  getReportingControlTestingStatus,
  getReportingProcessWiseRating,
  getReportingProcessWiseGapCategory,
  getReportingRatingCountByProcess,
  getReportingRatingCount,
  getFollowupObservationsAuditStatus,
  getFollowupObservationsRating,
  getFollowupObservationsByActionOwner,
  getFollowupObservationsByAgeing,
};

export default dashboardService;
