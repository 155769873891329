import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Imprint = () => {
  const navigate = useNavigate();
    useEffect(()=>{
        navigate('/imprints', {state:{module:"Imprint"}})
    },[])
  return(
  <div className="parsys sectionpar alignment">
    <div className="columnControl">
      <div className="parsys_column pwccol1-longform  ">
        <div className="parsys_column pwccol1-longform-c0">
          <div id="container-45e8dab79a" className="cmp-container">
            <div className="text parbase section">
              <div></div>

              <div className="text-component">
                <p>
                  The information contained in this site is for general guidance
                  on matters of interest only. The application and impact of
                  laws can vary widely based on the specific facts involved.
                  Given the changing nature of laws, rules and regulations, and
                  the inherent hazards of electronic communication, there may be
                  delays, omissions or inaccuracies in information contained in
                  this site. Accordingly, the information on this site is
                  provided with the understanding that the authors and
                  publishers are not herein engaged in rendering legal,
                  accounting, tax, or other professional advice and services. As
                  such, it should not be used as a substitute for consultation
                  with professional accounting, tax, legal or other competent
                  advisers. Before making any decision or taking any action, you
                  should consult a PwC professional.
                </p>
                <p>
                  While we have made every attempt to ensure that the
                  information contained in this site has been obtained from
                  reliable sources, PwC is not responsible for any errors or
                  omissions, or for the results obtained from the use of this
                  information. All information in this site is provided "as is",
                  with no guarantee of completeness, accuracy, timeliness or of
                  the results obtained from the use of this information, and
                  without warranty of any kind, express or implied, including,
                  but not limited to warranties of performance, merchantability
                  and fitness for a particular purpose. In no event will PwC,
                  its related partnerships or corporations, or the partners,
                  agents or employees thereof be liable to you or anyone else
                  for any decision made or action taken in reliance on the
                  information in this Site or for any consequential, special or
                  similar damages, even if advised of the possibility of such
                  damages.
                </p>
                <p>
                  Certain links in this site connect to other websites
                  maintained by third parties over whom PwC has no control. PwC
                  makes no representations as to the accuracy or any other
                  aspect of information contained in other websites.
                </p>
              </div>

              <script src="/etc.clientlibs/pwc/components/modernized/content/richtext/clientlibs-button.min.05014ed24e6422289f02e026aef427f0.js"></script>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="text parbase section">
      <div></div>
    </div>
  </div>
  )
};

export default Imprint;
