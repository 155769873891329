import { Spinner } from "react-bootstrap";
import styled from "styled-components";

type IProps = { $iscontent: boolean };

const LoadContainer = styled.div<{ $iscontent: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: ${(props: IProps) => (!props.$iscontent ? "100vh" : "auto")};
`;

const LoadSpinner = ({ $iscontent }: IProps) => {
  return (
    <LoadContainer $iscontent={$iscontent}>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </LoadContainer>
  );
};

export default LoadSpinner;
