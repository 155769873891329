import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "../../../assets/css/Notification.css";
import { toast } from "react-toastify";
import EditNotification from "./EditNotification";
import Spinner from "react-bootstrap/Spinner";
import { Switch } from "@appkit4/react-components/switch";
import { Input, Select } from "@appkit4/react-components";
import { Link } from "react-router-dom";
import useHttp from "../../../hooks/useHttp";
import APIService from "../../../services/api.service";
import usePermission from "../../../hooks/UsePermission";
import { IUserRole, SubModules } from "../../../models/user.model";
import { escapeRegExp } from "../../../common/EscapeRegex";

//  Internally, customStyles will deep merges your customStyles with the default styling.
const customStyles = {
  rows: {
    style: {
      minHeight: "45px", // override the row height
    },
  },
  headRow: {
    style: {
      minHeight: "45px",
    },
  },
  pagination: {
    style: {
      minHeight: "45px",
    },
  },
  headCells: {
    style: {
      backgroundColor: "#F2F2F2", // override the cell padding for head cells
      color: "black",
    },
  },
  cells: {
    style: {
      color: "#2D2D2D",
      fontSize: "14",
      backgroundColor: "#FFFFFF",
    },
  },
  subHeader: {
    style: {
      padding: 0,
      marginTop: "10px",
      backgroundColor: "#F2F2F2",
      color: "black",
      justifyContent: "flex-end",
      marginBottom: "15px",
    },
  },
};

function ViewAlert(props: any) {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState([]);
  const [onViewModalData, setOnViewModalData] = useState({});
  const [editFormData, setEditFormData] = useState();
  const [isViewModal, setIsViewModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clientID, setClientID] = useState();
  const [projectID, setProjectID] = useState<any>();
  const [clientList, setClientList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const { sendRequest: clientsAPI } = useHttp();
  const { sendRequest: getProjectDropdown } = useHttp();
  const { sendRequest: getNotificationList } = useHttp();
  const { sendRequest: updateEmailNotificationAPI } = useHttp();
  const [alertPermission, setAlertPermission] = useState({} as IUserRole);
  const { validateFeature } = usePermission();

  useEffect(() => {
    fetchClientData();
    validateFeature(SubModules.setup_alert).then((data: any) => {
      setAlertPermission(data.data.permissions[SubModules.setup_alert]);
    });
  }, []);
  const columns: any = [
    {
      name: "Module",
      selector: (row: any) => row.module,
      sortable: true,
      filterable: true,
    },
    {
      name: "Sub Module",
      selector: (row: any) => row.sub_module,
      sortable: true,
      filterable: true,
    },
    {
      name: "Operation",
      selector: (row: any) => row.operation,
      sortable: true,
      filterable: true,
    },
    {
      name: "Type",
      selector: (row: any) => row.notification_type,
      sortable: true,
      filterable: true,
    },

    {
      name: "Action",
      style: { position: "sticky", backgroundColor: "white", right: 0 },
      cell: (row: any) =>
        GetActions(
          row,
          NotificationViewButton,
          NotificationEditButton,
          SetEmailPermission
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const fetchClientData = async () => {
    await clientsAPI(APIService.getAlertClients(), (json: any) => {
      setClientList(json?.data);
      setClientID(json?.data[0].value);
      fetchProjectList(json?.data[0].value);
    });
  };
  const fetchProjectList = (value: any) => {
    getProjectDropdown(
      APIService.getAlertProjectDropdown(value),
      (json: any) => {
        setProjectList(json?.data);
        setProjectID(json?.data[0].value);
      }
    );
  };

  const fetchdata = async (clientID: any, projectID: any) => {
    if (clientID > 0 && projectID > 0) {
      setIsLoading(true);
      await getNotificationList(
        APIService.getNotificationList(clientID, projectID),
        (json: any) => {
          if (Array.isArray(json?.data)) {
            setData(json?.data);
            setFilter(json?.data);
            setIsLoading(false);
          }
        }
      );
    }
  };

  useEffect(() => {
    if (clientID && projectID) {
      fetchdata(clientID, projectID);
    }
  }, [clientID, projectID]);

  useEffect(() => {
    const result = data.filter((notification: any) => {
      return notification.module
        .toLowerCase()
        .match(escapeRegExp(search.toLocaleLowerCase()));
    });

    setFilter(result);
  }, [search]);

  const onChangeClient = (val: any) => {
    setClientID(val);
    setProjectID(null);
    fetchProjectList(val);
  };
  const onChangeProject = (val: any) => {
    setProjectID(val);
  };

  const NotificationEditButton = (row: any) => {
    setOnViewModalData(row);
    setEditFormData({
      ...row,
    });
  };
  const NotificationViewButton = (row: any) => {
    setIsViewModal(true);
    setOnViewModalData(row);
    setEditFormData({
      ...row,
    });
  };
  const handleEditModalConfirm = (row: any) => {
    setIsViewModal(false);
  };

  const SetEmailPermission = (val: any, sel: any) => {
    const data = {
      client_id: val.client_id,
      project_id: val.project_id,
      notification_id: val.notification_id,
      status: sel,
    };
    updateEmailNotificationAPI(
      APIService.updateEmailNotification(data),
      updateEmailNotificationResposne
    );
  };

  const updateEmailNotificationResposne = (json: any) => {
    if (json.status === "success") {
      fetchdata(clientID, projectID);
      toast.success("Notification updated successfully!!");
    } else {
      toast.error("Notification updated failed!!");
    }
  };

  function GetActions(
    row: any,
    NotificationViewButton: any,
    NotificationEditButton: any,
    SetEmailPermission: any
  ) {
    return (
      <div className="notificationAlignment">
        <div>
          {alertPermission.view ? (
            <span
              className="Appkit4-icon icon-view-fill icon-color ap-font-15"
              title="view"
              onClick={() => NotificationViewButton(row)}
            />
          ) : (
            ""
          )}

          <Link to={`update/${row.notification_id}/${clientID}/${projectID}`}>
            {alertPermission.edit ? (
              <span
                className="Appkit4-icon icon-edit-outline icon-color ap-font-15 "
                title="edit"
                onClick={() => NotificationEditButton(row)}
              />
            ) : (
              ""
            )}
          </Link>
        </div>
        <div>
          <Switch
            className="NotificationToggle"
            onChange={(sel) => SetEmailPermission(row, sel)}
            checked={row.status}
          ></Switch>
        </div>
      </div>
    );
  }
  return isLoading ? (
    <div className="content-loading">
      <Spinner animation="border">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  ) : alertPermission.view ? (
    <div className="pad-3">
      <div className="row">
        <div className="row mt-3">
          <div className="col-3">
            <div className="form-group">
              <Select
                data={Array.isArray(clientList) ? clientList : []}
                value={clientID}
                searchable={true}
                searchPlaceholder="Search Client"
                placeholder="Client"
                required={true}
                onSelect={onChangeClient}
              ></Select>
            </div>
          </div>
          <div className="col-3">
            <Select
              data={Array.isArray(projectList) ? projectList : []}
              value={projectID}
              searchable={true}
              searchPlaceholder="Search Project"
              placeholder="Project"
              required={true}
              onSelect={onChangeProject}
            ></Select>
          </div>
        </div>
        <div className="table-demo a-apply-theme-background-gray-level-1 openalert">
          <DataTable
            columns={columns}
            data={Array.isArray(filter) ? filter : []}
            pagination={true}
            responsive={true}
            striped
            customStyles={customStyles}
            subHeader
            subHeaderComponent={
              <Input
                type="text"
                title="Search"
                value={search}
                onChange={(e: any) => setSearch(e)}
                className="w-25 mar-10"
              />
            }
          />

          <EditNotification
            handleEditModalConfirm={handleEditModalConfirm}
            isViewModal={isViewModal}
            onViewModalData={onViewModalData}
            editFormdata={editFormData ? editFormData : ""}
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="content-loading">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
}
export default ViewAlert;
