import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import "../assets/css/AuditBreadcrumb.css";
import { Fragment, useContext, useEffect } from "react";
import { IDropdown } from "../common/CommonInterface";
import { IUserData } from "../models/user.model";

import useHttp from "../hooks/useHttp";
import APIService from "../services/api.service";
import { BreadcrumbItem } from "@appkit4/react-components";
import { AuditFilterContext, IFilterContext } from "./AuditFilterProvider";

export type ISelectValue = {
  value: number;
  label: string;
};

export type IFilter = {
  Client: ISelectValue;
  Project: ISelectValue;
  Plan: ISelectValue;
};

type IProps = {
  handleChange: any;
  setLoader: any;
  userData: IUserData;
  options?: any;
};

const truncate = (str: string, max: number = 8) => {
  return str.length > max ? str.substring(0, max) + "..." : str;
};

const AuditFilterBreadcrumb = ({
  handleChange,
  setLoader,
  userData,
  options,
}: IProps) => {
  const {
    filters,
    setFilters,
    clientList,
    setClientList,
    projectList,
    setProjectList,
    planList,
    setPlanList,
    isProjectFilter,
  }: IFilterContext = useContext(AuditFilterContext);
  const { sendRequest: getClients } = useHttp();
  const { sendRequest: getAuditProjects } = useHttp();
  const { sendRequest: getProjectAudits } = useHttp();

  useEffect(() => {
    loadValues();
  }, []);

  useEffect(() => {
    if (
      options.plan &&
      !isProjectFilter &&
      !filters?.Plan?.value &&
      planList.length > 0
    ) {
      setFilters((prev: any) => ({ ...prev, Plan: planList[0] }));
    }
  }, [options?.plan, planList?.length, isProjectFilter]);

  const loadValues = async () => {
    setLoader(true);
    const clientId = await fetchClients();
    setProjectList([]);
    setPlanList([]);
    await fetchProjects(clientId);
    if (filters?.Project?.value > 0) {
      await fetchPlan(filters?.Client?.value, filters?.Project?.value);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const fetchClients = async () => {
    return new Promise((resolve) =>
      getClients(APIService.getAuditClients(userData.userid), (data: any) => {
        setClientList(data.ClientList);
        if (!filters?.Client?.value) {
          setFilters({ Client: data?.ClientList[0] } as IFilter);
        }
        resolve(data?.ClientList[0]?.value);
      })
    );
  };

  const fetchProjects = async (clntId: any) => {
    return getAuditProjects(
      APIService.getAuditProjects(userData.userid, clntId),
      (data: any) => {
        setProjectList(data.ProjectList);
        if (!filters?.Project?.value) {
          setFilters((prev: any) => ({
            ...prev,
            Project: data.ProjectList[0],
          }));
          fetchPlan(clntId, data.ProjectList[0].value);
        }
      }
    );
  };

  const fetchPlan = async (clientId: any, projectId: any) => {
    return getProjectAudits(
      APIService.getProjectAudits(clientId, projectId),
      (data: any) => {
        const plans = data.AuditData.map(
          (i: any): IDropdown => ({ value: i.AuditPlanID, label: i.PlanName })
        );
        setPlanList(plans);
      }
    );
  };
  return (
    <Fragment>
      <BreadcrumbItem>
        <span className="wt_label2" tabIndex={0}>
          <div className="nav-breadcrumb">
            <DropdownButton
              as={ButtonGroup}
              id={`dropdown-button-drop`}
              size="sm"
              variant="secondary"
              menuVariant="dark"
              title={
                <span title={filters?.Client?.label}>
                  <span className="Appkit4-icon icon-partership-outline"></span>
                  <label className="bc-label">
                    {filters?.Client?.label?.length > 0
                      ? truncate(filters?.Client?.label)
                      : "Client"}
                  </label>
                </span>
              }
            >
              {clientList.map((i: IDropdown) => (
                <Dropdown.Item
                  eventKey="1"
                  key={i.value}
                  onClick={() => {
                    setFilters({ Client: i } as IFilter);
                    fetchProjects(i.value);
                  }}
                >
                  {i.label}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
        </span>
      </BreadcrumbItem>
      {projectList.length > 0 && (
        <BreadcrumbItem>
          <span className="wt_label2" tabIndex={0}>
            <div className="nav-breadcrumb">
              <DropdownButton
                as={ButtonGroup}
                id={`dropdown-button-drop`}
                size="sm"
                variant="secondary"
                menuVariant="dark"
                title={
                  <span title={filters?.Project?.label}>
                    <span className="Appkit4-icon icon-management-outline"></span>
                    <label className="bc-label">
                      {filters?.Project?.label?.length > 0
                        ? truncate(filters?.Project.label)
                        : "Project"}
                    </label>
                  </span>
                }
              >
                {projectList.map((i: IDropdown) => (
                  <Dropdown.Item
                    eventKey="1"
                    key={i.value}
                    onClick={() => {
                      setFilters({
                        ...filters,
                        Project: i,
                        Plan: 0,
                      });
                      setPlanList([]);
                      fetchPlan(filters?.Client?.value, i.value);
                      if (isProjectFilter || !options?.plan) {
                        handleChange(i.value);
                      }
                    }}
                  >
                    {i.label}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>
          </span>
        </BreadcrumbItem>
      )}
      {planList.length > 0 && !isProjectFilter && options?.plan && (
        <BreadcrumbItem>
          <span className="wt_label2" tabIndex={0}>
            <div className="nav-breadcrumb">
              <DropdownButton
                as={ButtonGroup}
                id={`dropdown-button-drop`}
                size="sm"
                variant="secondary"
                menuVariant="dark"
                title={
                  <span title={filters?.Plan?.label}>
                    <span className="Appkit4-icon icon-document-file-outline"></span>
                    <label className="bc-label">
                      {filters?.Plan?.label?.length > 0
                        ? truncate(filters?.Plan.label)
                        : "Plan"}
                    </label>
                  </span>
                }
              >
                {planList.map((i: IDropdown) => (
                  <Dropdown.Item
                    eventKey="1"
                    key={i.value}
                    onClick={() => {
                      setFilters({
                        ...filters,
                        Plan: i,
                      });
                      handleChange(i.value);
                    }}
                  >
                    {i.label}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>
          </span>
        </BreadcrumbItem>
      )}
    </Fragment>
  );
};

export default AuditFilterBreadcrumb;
