import { useState, useCallback } from "react";
import { HttpMethods } from "../models/constants";
import { toast } from "react-toastify";

const useHttp = () => {
  const [error, setError] = useState<boolean>(false);
  const sendRequest = useCallback(
    async (
      config: any,
      responseAction: (data: any) => any,
      failureAction?: (data: any) => any,
      fullResponse: boolean = false
    ) => {
      try {
        const method: HttpMethods = config?.method
          ? config?.method
          : HttpMethods.GET;
        const body: {} | undefined = config?.body ? config?.body : undefined;
        const headers: {} | undefined = config?.headers
          ? config?.headers
          : undefined;
        const content =
          method === HttpMethods.POST ? [body, headers] : [headers];
        const res: any = await config.instance[method](config.url, ...content);
        responseAction(fullResponse ? res : res?.data);
        setError(false);
      } catch (err: any) {
        setError(true);
        if (failureAction) {
          failureAction(err);
        } else {
          toast.error(err.detail);
        }
      }
    },
    []
  );
  return { sendRequest, error };
};

export default useHttp;
