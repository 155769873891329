import axiosInstance from "../models/axios.instance";
import { HttpMethods, URLS } from "../models/constants";
import { HttpConfig } from "../models/types";

const getDepartmentTemplateDoc = (name: String): HttpConfig => {
    return {
        instance: axiosInstance.APIInstance,
        method: HttpMethods.GET,
        headers: {
            responseType: "blob"
        },
        url: `${URLS.AUDIT_MANAGEMENT_DEPARTMENT_TEMPLATE_DOWNLOAD + name}`
    }
};

const getDepartmentList = (): HttpConfig =>{
    return {
        instance: axiosInstance.APIInstance,
        method: HttpMethods.GET,
        url: `${URLS.AUDIT_MANAGEMENT_DEPARTMENT_LIST}`
    }
}

const getDepartmentDuplicate = (name: String): HttpConfig =>{
    return {
        instance: axiosInstance.APIInstance,
        method: HttpMethods.GET,
        url: `${URLS.AUDIT_MANAGEMENT_DEPARTMENT_DUPLICATE + name}`
    }
}

const editDepartment = (id:number, data: any): HttpConfig => {
    return {
        instance: axiosInstance.APIInstance,
        method: HttpMethods.POST,
        url: `${URLS.AUDIT_MANAGEMENT_DEPARTMENT_EDIT + id}`,
        body: data
    }
}

const addDepartment = (data: any): HttpConfig => {
    return {
        instance: axiosInstance.APIInstance,
        method: HttpMethods.POST,
        url: `${URLS.AUDIT_MANAGEMENT_DEPARTMENT_ADD}`,
        body: data
    }
}

const addDepartmentBulk = (data: any): HttpConfig => {
    return {
        instance: axiosInstance.APIInstance,
        method: HttpMethods.POST,
        url: `${URLS.AUDIT_MANAGEMENT_DEPARTMENT_ADD_BULK}`,
        body: data
    }
}

const auditManagementDepartmentServices = {
    getDepartmentTemplateDoc,
    getDepartmentList,
    getDepartmentDuplicate,
    editDepartment,
    addDepartment,
    addDepartmentBulk
}

export default auditManagementDepartmentServices;