import { useNavigate, useLocation } from "react-router-dom";
import { UserContext } from "../UserContext";
import { Fragment, useContext, useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import "../assets/css/Common.css";
import useHttp from "../hooks/useHttp";
import APIService from "../services/api.service";
import { Button, Modal } from "@appkit4/react-components";
import { SocketContext } from "../hooks/use-socket";

export default function CorporateLogin(props: any) {
  const { setUserNotFound , setErrorMessage} = props;
  const location = useLocation();
  const user = useContext<any>(UserContext);
  const socket: any = useContext(SocketContext);
  const [shouldLogout, setShouldLogout] = useState(false);
  const [loader, setLoader] = useState(true);
  const { sendRequest: ssoSessionAPI } = useHttp();
  const { sendRequest: ssoTokenAPI } = useHttp();
  const { sendRequest: ssoUserAPI } = useHttp();
  const navigate = useNavigate();

  const endPoint = location.pathname.slice(1) + location.search.split("&")[0];

  const ssoLoginResponse = (
    data: any,
    defaultRoute: any,
    isExternal?: boolean
  ) => {
    user.setUserData(data.UserDetails);
    user.setIsLoggedIn(true);
    setLoader(false);
    if (isExternal || data.UserDetails?.is_admin) {
      navigate(defaultRoute?.path, {
        state: {
          module: defaultRoute?.module,
          submodule: defaultRoute?.submodule,
        },
      });
    } else {
      navigate("/");
    }
    socket.socketConnect();
  };

  useEffect(() => {
    if (endPoint?.includes("code")) {
      ssoSessionAPI(
        APIService.getSsoSession(endPoint),
        (data: any) => {
          ssoTokenAPI(
            APIService.getSsoToken(data.sessionId),
            (response: any) => {
              if (response.access_token) {
                localStorage.setItem("SessionID", response.SessionID);
                localStorage.setItem("access_token", response.access_token);
                ssoUserAPI(
                  APIService.getUserInfo(),
                  (data: any) =>
                    ssoLoginResponse(
                      data,
                      response?.DefaultRoute,
                      response?.user_type
                    ),
                  (error) => {
                    navigate("/");
                    setLoader(false);
                  }
                );
              }
            },
            (err) => {
              if (!err?.response?.data?.sso_login) {
                user.setUserData({
                  user_id: err?.response.data.user_id,
                });
                setShouldLogout(true);
              }
              setLoader(false);
            }
          );
        },
        (err) => {
          if (err.response.status === 404) {
            setUserNotFound(true);
            setErrorMessage(err?.response?.data?.detail?.message)
          } else {
            navigate("/");
          }
          setLoader(false);
        }
      );
    }
  }, [endPoint]);

  return (
    <Fragment>
      {loader && (
        <div className="loading">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <Modal
        visible={shouldLogout}
        title={"Session Exists!"}
        ariaLabel={"Session Exists!"}
        onCancel={() => {
          setUserNotFound(false);
          user.logout(user.userData?.userid);
        }}
        onClose={() => {
          setUserNotFound(false);
          user.logout(user.userData?.userid);
        }}
        modalStyle={{ width: "33.75rem" }}
        footerStyle={{
          paddingTop: "8px",
          marginTop: "-8px",
          minHeight: "64px",
        }}
        footer={
          <Button
            kind="primary"
            className="btn btn-primary btn-block login-button-color"
            onClick={() => {
              user.logout(user.userData?.userid);
            }}
          >
            Logout
          </Button>
        }
      >
        <p>The last session is not terminated properly, please logout!</p>
      </Modal>
    </Fragment>
  );
}
