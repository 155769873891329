import axiosInstance from "../models/axios.instance";
import { HttpMethods, URLS } from "../models/constants";
import { HttpConfig } from "../models/types";

const addCatalogueBulk = (data: any): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.POST,
    url: `${URLS.AUDIT_MANAGEMENT_CATALOGUE_ADD_BULK}`,
    body: data,
  };
};
const getCatalogueTemplateDoc = (): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    headers: {
      responseType: "blob",
    },
    url: `${URLS.AUDIT_MANAGEMENT_CATALOGUE_TEMPLATE_DOWNLOAD}`,
  };
};

const getObservations = (): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: URLS.AUDIT_MANAGEMENT_CATALOGUE_OVERVIEW,
  };
};

const auditManagementCatalogueServices = {
  addCatalogueBulk,
  getCatalogueTemplateDoc,
  getObservations,
};

export default auditManagementCatalogueServices;
