import { useState } from "react";
import APIService from "../services/api.service";
import useHttp from "./useHttp";

const usePermission = () => {
  const { sendRequest: getFeaturePermissions } = useHttp();
  const [loader, setLoader] = useState(false);

  const validateFeature = (value: any) => {
    return new Promise((resolve, reject) => {
      setLoader(true);
      getFeaturePermissions(
        APIService.getFeaturePermissions(value),
        (data: any) => {
          resolve(data);
          setLoader(false);
        },
        (err) => {
          reject(err);
          setLoader(false);
        }
      );
    });
  };
  return { validateFeature, loader };
};

export default usePermission;
