import { lazy, Suspense, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Imprint from "./Imprint";
import Privacy from "./Privacy";
import Cookies from "./Cookies";
import Credits from "./Credits";
import UnAuthorised from "./components/UnAuthorized";
import ViewAlert from "./components/Audit/Alert/ViewAlert";
import EditAlert from "./components/Audit/Alert/EditAlert";
import AppGaurd from "./AppGaurd";
import App from "./App";
import CorporateLogin from "./components/CorporateLogin";

const Login = lazy(() => import("./components/Login"));
const Register = lazy(() => import("./components/Register"));

const UserProfile = lazy(() => import("./components/UserProfile"));
const Client = lazy(() => import("./components/Setup/Client/Client"));
const UserNotFound = lazy(() => import("./UserNotFound"));
const Project = lazy(() => import("./components/Setup/Project/Project"));
const User = lazy(() => import("./components/Audit/User/User"));
const Dashboard = lazy(() => import("./components/FollowUp/Dashboard"));
const ObservationsFollowUpHome = lazy(
  () =>
    import(
      "./components/FollowUp/ObservationsManagement/ObservationsFollowUpHome"
    )
);
const Home = lazy(() => import("./components/Reporting/Home"));
const Process = lazy(() => import("./components/Audit/Process/Home"));
const Department = lazy(() => import("./components/Audit/Department/Home"));
const Subprocess = lazy(() => import("./components/Audit/Subprocess/Home"));
const Type = lazy(() => import("./components/Audit/Type/Home"));
const Catalogue = lazy(() => import("./components/Audit/Catalogue/Home"));
const RiskCatalogue = lazy(
  () => import("./components/Audit/RiskCatalogue/Home")
);
const AuditPlanning = lazy(
  () => import("./components/AuditManagement/AuditPlan")
);
const Feedback = lazy(() => import("./components/Feedback/Home"));
const FieldWork = lazy(() => import("./components/AuditExecution/FieldWork"));
const RiskControlMapping = lazy(
  () => import("./components/AuditExecution/RiskControlMap/RiskControlMapMain")
);
const AuditHistory = lazy(
  () => import("./components/Audit/Audit History/AuditHistory")
);
const HomeDashboard = lazy(() => import("./components/Home/HomeDashboard"));

const DataRequest = lazy(
  () => import("./components/AuditExecution/DataRequest/Home")
);

const LazyElement = ({ children }: any) => {
  return (
    <Suspense
      fallback={
        <div className="content-loading">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      }
    >
      {children}
    </Suspense>
  );
};

const Location = lazy(() => import("./components/Audit/Location/Home"));

const MainRoute = () => {
  const [isUserNotFound, setIsUserNotFound] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <div>
      <Routes>
        <Route
          path="/login"
          element={
            <LazyElement>
              <Login />
            </LazyElement>
          }
        />
        <Route
          path="/register"
          element={
            <LazyElement>
              <Register />
            </LazyElement>
          }
        />
        <Route
          path={"/privacy"}
          element={
            <LazyElement>
              <Privacy />
            </LazyElement>
          }
        />
        <Route
          path="/auth/ssoSuccess/*"
          element={
            <LazyElement>
              <CorporateLogin
                setUserNotFound={setIsUserNotFound}
                setErrorMessage={setErrorMessage}
              />
            </LazyElement>
          }
        />
        <Route path="/" element={<App />}>
          <Route
            path="/user-profile"
            element={
              <LazyElement>
                <AppGaurd>
                  <UserProfile />
                </AppGaurd>
              </LazyElement>
            }
          />
          <Route
            path="/setup/Client"
            element={
              <LazyElement>
                <AppGaurd>
                  <Client />
                </AppGaurd>
              </LazyElement>
            }
          />
          <Route
            path="/setup/Project"
            element={
              <LazyElement>
                <AppGaurd>
                  <Project />
                </AppGaurd>
              </LazyElement>
            }
          />
          <Route
            path="/app-management/Alert"
            element={
              <LazyElement>
                <AppGaurd>
                  <ViewAlert />
                </AppGaurd>
              </LazyElement>
            }
          />
          <Route
            path="/app-management/Alert/update/:id/:client/:project"
            element={
              <LazyElement>
                <AppGaurd>
                  <EditAlert />
                </AppGaurd>
              </LazyElement>
            }
          />
          <Route
            path="/follow-up/dashboard"
            element={
              <LazyElement>
                <AppGaurd>
                  <Dashboard />
                </AppGaurd>
              </LazyElement>
            }
          />
          <Route
            path="/follow-up/observation-management"
            element={
              <LazyElement>
                <AppGaurd>
                  <ObservationsFollowUpHome />
                </AppGaurd>
              </LazyElement>
            }
          />
          <Route
            path="/audit-exection/DataRequest"
            element={
              <LazyElement>
                <DataRequest />
              </LazyElement>
            }
          />
          <Route
            path="/audit-exection/FieldWork"
            element={
              <LazyElement>
                <FieldWork />
              </LazyElement>
            }
          />
          <Route
            path="/audit-exection/RiskControlMapping"
            element={
              <LazyElement>
                <RiskControlMapping />
              </LazyElement>
            }
          />
          <Route
            path={"/imprints"}
            element={
              <LazyElement>
                <Imprint />
              </LazyElement>
            }
          />

          <Route
            path="/cookies"
            element={
              <LazyElement>
                <Cookies />
              </LazyElement>
            }
          />
          <Route
            path="/credits"
            element={
              <LazyElement>
                <Credits />
              </LazyElement>
            }
          />
          <Route
            path="/app-management/user"
            element={
              <LazyElement>
                <AppGaurd>
                  <User />
                </AppGaurd>
              </LazyElement>
            }
          />
          <Route
            path="/app-management/process-management"
            element={
              <LazyElement>
                <AppGaurd>
                  <Process />
                </AppGaurd>
              </LazyElement>
            }
          />

          <Route
            path="/app-management/department-management"
            element={
              <LazyElement>
                <AppGaurd>
                  <Department />
                </AppGaurd>
              </LazyElement>
            }
          />
          <Route
            path="/app-management/sub-management"
            element={
              <LazyElement>
                <AppGaurd>
                  <Subprocess />
                </AppGaurd>
              </LazyElement>
            }
          />
          <Route
            path="/app-management/type-management"
            element={
              <LazyElement>
                <AppGaurd>
                  <Type />
                </AppGaurd>
              </LazyElement>
            }
          />
          <Route
            path="/app-management/location-management"
            element={
              <LazyElement>
                <AppGaurd>
                  <Location />
                </AppGaurd>
              </LazyElement>
            }
          />
          <Route
            path="/app-management/risk-cat"
            element={
              <LazyElement>
                <AppGaurd>
                  <RiskCatalogue />
                </AppGaurd>
              </LazyElement>
            }
          ></Route>
          <Route
            path="/app-management/observation-catalogue"
            element={
              <LazyElement>
                <AppGaurd>
                  <Catalogue />
                </AppGaurd>
              </LazyElement>
            }
          />
          <Route
            path="/app-management/history"
            element={
              <LazyElement>
                <AppGaurd>
                  <AuditHistory />
                </AppGaurd>
              </LazyElement>
            }
          />

          <Route
            path="/audit-planning"
            element={
              <LazyElement>
                <AppGaurd>
                  <AuditPlanning />
                </AppGaurd>
              </LazyElement>
            }
          />
          <Route
            path="/report-home"
            element={
              <LazyElement>
                <AppGaurd>
                  <Home />
                </AppGaurd>
              </LazyElement>
            }
          />
          <Route
            path="/feedback"
            element={
              <LazyElement>
                <AppGaurd>
                  <Feedback />
                </AppGaurd>
              </LazyElement>
            }
          />
          <Route
            path="/unauthorised"
            element={
              <LazyElement>
                <UnAuthorised />
              </LazyElement>
            }
          />
          <Route
            path="/"
            element={
              <LazyElement>
                <HomeDashboard />
              </LazyElement>
            }
          />
        </Route>
      </Routes>
      <ToastContainer />
      <UserNotFound
        isUserNotFound={isUserNotFound}
        setUserNotFound={setIsUserNotFound}
        message={errorMessage}
      />
    </div>
  );
};
export default MainRoute;
