import axiosInstance from "../models/axios.instance";
import { HttpMethods, URLS } from "../models/constants";
import { HttpConfig } from "../models/types";



const getDATATYPE = (): HttpConfig =>{
    return {
        instance: axiosInstance.APIInstance,
        method: HttpMethods.GET,
        url: `${URLS.DATA_REQUEST_TYPE}`
    }
}

const addDataRequest = (data: any): HttpConfig => {
    return {
        instance: axiosInstance.APIInstance,
        method: HttpMethods.POST,
        url: `${URLS.DATA_REQUEST_CREATE}`,
        body: data
    }
}
const DataRequestList = (): HttpConfig => {
    return {
        instance: axiosInstance.APIInstance,
        method: HttpMethods.GET,
        url: `${URLS.DATA_REQUEST_LIST}`,
    }
}
const DataRequestStatus = (): HttpConfig => {
    return {
        instance: axiosInstance.APIInstance,
        method: HttpMethods.GET,
        url: `${URLS.DATA_REQUEST_STATUS}`,
    }
}

const getDataRequestDocs = (dataRequestID: number): HttpConfig => {
    return {
      instance: axiosInstance.APIInstance,
      method: HttpMethods.GET,
      url: `${URLS.DATA_REQUEST_DOCS}/${dataRequestID}`,
    };
  };


  const getEditDataRequest = (ID: any ,data: any): HttpConfig => {
    return {
        instance: axiosInstance.FileUploadInstance,
        method: HttpMethods.POST,
        url: `${URLS.DATA_REQUEST_EDIT + ID}`,
        body: data
    }
}

  const getDataRequestDocsDownload = (dataRequestID: number): HttpConfig => {
    return {
        instance: axiosInstance.APIInstance,
        method: HttpMethods.GET,
        headers: {
            responseType: "blob"
        },
        url: `${URLS.DATA_REQUEST_DOWNLOAD_DOCS}/${dataRequestID}`
    }
};

const getUsers = (): HttpConfig => {
    return {
      instance: axiosInstance.APIInstance,
      method: HttpMethods.GET,
      url: URLS.USER_LIST,
    };
  };

const auditExecutionDataRequest = {
    getDATATYPE,
    addDataRequest,
    DataRequestList,
    getDataRequestDocs,
    getDataRequestDocsDownload,
    DataRequestStatus,
    getEditDataRequest,
    getUsers
}

export default auditExecutionDataRequest;