import axiosInstance from "../models/axios.instance";
import { HttpMethods, URLS } from "../models/constants";
import { HttpConfig } from "../models/types";

const getLocationTemplateDoc = (name: String): HttpConfig => {
    return {
        instance: axiosInstance.APIInstance,
        method: HttpMethods.GET,
        headers: {
            responseType: "blob"
        },
        url: `${URLS.AUDIT_MANAGEMENT_LOCATION_TEMPLATE_DOWNLOAD + name}`
    }
};

const getLocationList = (): HttpConfig =>{
    return {
        instance: axiosInstance.APIInstance,
        method: HttpMethods.GET,
        url: `${URLS.AUDIT_MANAGEMENT_LOCATION_LIST}`
    }
}

const getLocationDuplicate = (name: String): HttpConfig =>{
    return {
        instance: axiosInstance.APIInstance,
        method: HttpMethods.GET,
        url: `${URLS.AUDIT_MANAGEMENT_LOCATION_DUPLICATE + name}`
    }
}

const editLocation = (id:number, data: any): HttpConfig => {
    return {
        instance: axiosInstance.APIInstance,
        method: HttpMethods.POST,
        url: `${URLS.AUDIT_MANAGEMENT_LOCATION_EDIT + id}`,
        body: data
    }
}

const addLocation = (data: any): HttpConfig => {
    return {
        instance: axiosInstance.APIInstance,
        method: HttpMethods.POST,
        url: `${URLS.AUDIT_MANAGEMENT_LOCATION_ADD}`,
        body: data
    }
}

const addLocationBulk = (data: any): HttpConfig => {
    return {
        instance: axiosInstance.APIInstance,
        method: HttpMethods.POST,
        url: `${URLS.AUDIT_MANAGEMENT_LOCATION_ADD_BULK}`,
        body: data
    }
}

const auditManagementLocationServices = {
    getLocationTemplateDoc,
    getLocationList,
    getLocationDuplicate,
    editLocation,
    addLocation,
    addLocationBulk
}

export default auditManagementLocationServices;