import { Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Navigation } from "@appkit4/react-components/navigation";
import { Avatar } from "@appkit4/react-components/avatar";
import "./assets/css/Sidebar.scss";
import logo from "./assets/images/pwc-brand.png";

function GetActions(getLabel: any, redirectUserProfile: any, user: any) {
  return (
    <Fragment>
      <Avatar
        className="keyboard-focus"
        label={getLabel()}
        disabled={false}
        role="button"
        onClick={() => redirectUserProfile()}
      ></Avatar>
      <div
        className="user-name"
        onClick={() => redirectUserProfile()}
        onKeyDown={(e) => e.preventDefault()}
      >
        <div className="title">{user?.userData?.username}</div>
        <span className="subtitle">{user?.userData?.designation}</span>
      </div>
    </Fragment>
  );
}

function GetAppTitle(getDashboard: any) {
  return (
    <div
      className="logo-container"
      onClick={getDashboard}
      onKeyDown={(e) => e.preventDefault()}
    >
      <img src={logo} alt="Logo" className="d-inline-block align-bottom-mar" />
    </div>
  );
}

const SideMenu = (props: any) => {
  const { navList, permission, user, role } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const onClickLogo = (event: any) => {
    console.log("onClickLogo", event);
  };

  const redirect = (eventitem: any, item: any) => {
    if (item.name === "Setup Client") {
      navigate("/setup/Client", { state: { module: item.name } });
    } else if (item.name === "Audit Planning") {
      navigate("/audit-planning", {
        state: {
          module: item.name,
          filter: { client: true, project: true, plan: false },
        },
      });
    } else if (item.name === "Reporting") {
      navigate("/report-home", {
        state: {
          module: item.name,
          filter: { client: true, project: true, plan: true },
        },
      });
    } else if (item.name === "Feedback") {
      navigate("/feedback", { state: { module: item.name } });
    } else if (item.name === "Logout") {
      user.logout(user.userData?.userid);
    }
  };

  const redirectSub = (event: any, item: any) => {
    // routing logic here
    if (item.name === "Client") {
      navigate("/setup/Client", {
        state: { module: "Setup", submodule: item.name },
      });
    } else if (item.name === "Project") {
      navigate("/setup/Project", {
        state: { module: "Setup", submodule: item.name },
      });
    } else if (item.name === "Field Work") {
      navigate("/audit-exection/FieldWork", {
        state: {
          module: "Audit Execution",
          submodule: item.name,
          filter: { client: true, project: true, plan: true },
        },
      });
    } else if (item.name === "Risk & Control Mapping") {
      navigate("/audit-exection/RiskControlMapping", {
        state: {
          module: "Audit Execution",
          submodule: item.name,
          filter: { client: true, project: true, plan: true },
        },
      });
    } else if (item.name === "Data Request") {
      navigate("/audit-exection/DataRequest", {
        state: {
          module: "Audit Execution",
          submodule: item.name,
          userRole: role,
          filter: { client: true, project: true, plan: true },
        },
      });
    } else if (item.name === "Dashboard") {
      navigate("/follow-up/dashboard", {
        state: {
          module: "Follow up",
          submodule: item.name,
          filter: { client: true, project: true, plan: true },
        },
      });
    } else if (item.name === "Observation Management") {
      navigate("/follow-up/observation-management", {
        state: {
          module: "Follow up",
          submodule: item.name,
          userRole: role,
          filter: { client: true, project: true, plan: true },
        },
      });
    } else if (item.name === "Process Management") {
      navigate("/app-management/process-management", {
        state: { module: "App Management", submodule: item.name },
      });
    } else if (item.name === "Subprocess Management") {
      navigate("/app-management/sub-management", {
        state: { module: "App Management", submodule: item.name },
      });
    } else if (item.name === "Department Management") {
      navigate("/app-management/department-management", {
        state: { module: "App Management", submodule: item.name },
      });
    } else if (item.name === "Type Management") {
      navigate("/app-management/type-management", {
        state: { module: "App Management", submodule: item.name },
      });
    } else if (item.name === "Location Management") {
      navigate("/app-management/location-management", {
        state: { module: "App Management", submodule: item.name },
      });
    } else if (item.name === "User") {
      navigate("/app-management/user", {
        state: { module: "App Management", submodule: item.name },
      });
    } else if (item.name === "Alert") {
      navigate("/app-management/Alert", {
        state: { module: "App Management", submodule: item.name },
      });
    } else if (item.name === "Risk Catalogue") {
      navigate("/app-management/risk-cat", {
        state: { module: "App Management", submodule: item.name },
      });
    } else if (item.name === "Observation Catalogue") {
      navigate("/app-management/observation-catalogue", {
        state: { module: "App Management", submodule: item.name },
      });
    } else if (item.name === "Audit History") {
      navigate("/app-management/history", {
        state: { module: "App Management", submodule: item.name },
      });
    }
  };

  const getCurrentModule = () => {
    if (location.pathname.includes("setup")) {
      return 0;
    } else if (location.pathname.includes("audit-planning")) {
      return 1;
    } else if (location.pathname.includes("audit-exection")) {
      return 2;
    } else if (location.pathname.includes("report-home")) {
      return 3;
    } else if (location.pathname.includes("follow-up")) {
      return 4;
    } else if (location.pathname.includes("feedback")) {
      return 5;
    } else if (location.pathname.includes("app-management")) {
      return 6;
    }
    return -1;
  };

  const getCurrentSubModule = () => {
    const currentModule = getCurrentModule();
    switch (currentModule) {
      case 0:
        if (location.pathname.includes("Client")) {
          return 0;
        } else if (location.pathname.includes("User")) {
          return 1;
        }
        return location.pathname.includes("Project") ? 1 : -1;
      case 2:
        if (location.pathname.includes("DataRequest")) {
          return 0;
        }
        if (location.pathname.includes("RiskControlMapping")) {
          return 1;
        }
        return location.pathname.includes("FieldWork") ? 2 : -1;
      case 4:
        if (location.pathname.includes("dashboard")) {
          return 0;
        }
        return location.pathname.includes("observation-management") ? 1 : -1;
      case 6:
        if (location.pathname.includes("process-management")) {
          return 0;
        } else if (location.pathname.includes("sub-management")) {
          return 1;
        } else if (location.pathname.includes("department-management")) {
          return 2;
        } else if (location.pathname.includes("type-management")) {
          return 3;
        } else if (location.pathname.includes("location-management")) {
          return 4;
        } else if (location.pathname.includes("user")) {
          return 5;
        } else if (location.pathname.includes("observation-catalogue")) {
          return 6;
        } else if (location.pathname.includes("Alert")) {
          return 7;
        } else if (location.pathname.includes("risk-cat")) {
          return 8;
        }
        return location.pathname.includes("history") ? 9 : -1;
      default:
        return -1;
    }
  };

  const redirectUserProfile = () => {
    navigate("/user-profile", { state: { module: "User Profile" } });
  };

  const onCollapseEvent = (collapsed: any) => {
    props.onCollapse(collapsed);
  };

  const getLabel = () => {
    const userFullName =
      user.userData.username?.replace(/  +/g, " ")?.split(" ") ?? "";
    return (
      (userFullName[0] ? userFullName[0][0] : "") +
      (userFullName[1] ? userFullName[1][0] : "")
    );
  };

  const getDashboard = (e: any) => {
    e.preventDefault();
    navigate("/");
  };

  return (
    <div className="ap-navigation-demo-wrapper">
      <Navigation
        width={280}
        className="sitedemo"
        showTooltip={true}
        navList={navList}
        hasLogo={false}
        onClickLogo={onClickLogo}
        onClickItem={redirect}
        onClickSubItem={redirectSub}
        selectedIndex={getCurrentModule()}
        selectedSubIndex={getCurrentSubModule()}
        onClickCollapseEvent={onCollapseEvent}
        titleTemplate={() => GetAppTitle(getDashboard)}
        userTemplate={() =>
          permission.user_profile_menu.view
            ? GetActions(getLabel, redirectUserProfile, user)
            : ""
        }
      ></Navigation>
    </div>
  );
};

export default SideMenu;
