import { Fragment, useContext, useEffect, useState } from "react";
import { UserContext } from "./UserContext";
import { IUserContext, RouteSubModuleName } from "./models/user.model";
import usePermission from "./hooks/UsePermission";
import { useLocation, useNavigate } from "react-router-dom";
import LoadSpinner from "./common/LoadSpinner";

const AppGaurd = ({ children }: any) => {
  const { isLoggedIn }: IUserContext = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const { validateFeature } = usePermission();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      validateUser();
    }
  }, [isLoggedIn]);

  const validateUser = () => {
    const moduleName = location.pathname.split("/").pop() ?? "";
    const routeValue = RouteSubModuleName[moduleName];
    if (routeValue?.length > 0) {
      setIsLoading(true);
      validateFeature(routeValue)
        .then((data: any) => {
          const isAuthorised = data.data.permissions[routeValue]?.view;
          if (!isAuthorised) {
            navigate("/unauthorised");
          }
          setIsLoading(false);
        })
        .catch((err) => {
          navigate("/unauthorised");
          setIsLoading(false);
        });
    }
  };

  return (
    <Fragment>
      {isLoading && <LoadSpinner $iscontent={isLoggedIn} />}
      {!isLoading && children}
    </Fragment>
  );
};

export default AppGaurd;
