import { HttpConfig } from "../models/types";
import axiosInstance from "../models/axios.instance";
import { HttpMethods, URLS } from "../models/constants";

const getPublicContent = (): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.USER_TEST}/all`,
  };
};

const getUserBoard = (): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.USER_TEST}/user`,
  };
};

const getModeratorBoard = (): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.USER_TEST}/mod`,
  };
};

const getAdminBoard = (): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.USER_TEST}/admin`,
  };
};

const userTestService = {
  getPublicContent,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard,
};

export default userTestService;
